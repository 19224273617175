<template>
  <section>
    <Navbar />

  

      
    <div
      class="md:my-[180px] my-[110px] sm:max-w-[604px] max-w-[367px] lg:max-w-[972px] w-full mx-auto border md:px-[120px] px-6 py-[40px] rounded-lg bg-[#FDF5FF]"
    >
      <!-- go back using history mode -->
      <div @click="goBack" class="flex items-center justify-start gap-3 mb-6 cursor-pointer">
        <!-- arrrow back svg -->
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#6B46C1" class="size-4">
          <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
        </svg>
        
        <div class="text-[#6B46C1] text-[14px] font-bold"
          >Go Back</div
        >
      </div>


      <div
        class="flex items-center justify-center title md:!text-2xl text-lg mb-12 !font-semibold"
      >
        Kindly complete the form to enroll into CAMLDS
      </div>
      <form @submit.prevent="submitForm">
        <div class="mb-4">
          <label for="fullName" class="block text-black">Full Name </label>
          <input
            type="text"
            id="fullName"
            v-model="form.fullName"
            class="w-full px-3 py-2 border rounded"
          />
          <div v-if="errors.fullName" class="text-red-500 text-sm">
            {{ errors.fullName }}
          </div>
        </div>
        <div class="mb-4">
          <label for="email" class="block text-black">Email*</label>
          <input
            type="email"
            id="email"
            v-model="form.email"
            class="w-full px-3 py-2 border rounded"
          />
          <div v-if="errors.email" class="text-red-500 text-sm">
            {{ errors.email }}
          </div>
        </div>
        <div class="mb-4">
          <label for="phoneNumber" class="block text-black"
            >Phone Number*</label
          >
          <input
            type="text"
            id="phoneNumber"
            v-model="form.phoneNumber"
            class="w-full px-3 py-2 border rounded"
          />
          <div v-if="errors.phoneNumber" class="text-red-500 text-sm">
            {{ errors.phoneNumber }}
          </div>
        </div>
        <div class="mb-4">
          <label for="address" class="block text-black"
            >Address (Land Mark)*</label
          >
          <input
            type="text"
            id="address"
            v-model="form.address"
            class="w-full px-3 py-2 border rounded"
          />
          <div v-if="errors.address" class="text-red-500 text-sm">
            {{ errors.address }}
          </div>
        </div>
        <div class="mb-4">
          <label for="course" class="block text-black">Course*</label>
          <select
            id="course"
            v-model="form.course"
            class="w-full px-3 py-2 border rounded"
          >
            <option value="">Select a course</option>
            <option v-for="course in coursePref" :key="course" :value="course">
              {{ course }}
            </option>

          </select>
          <div v-if="errors.course" class="text-red-500 text-sm">
            {{ errors.course }}
          </div>
        </div>
        <div class="mb-4">
          <label for="heardAboutUs" class="block text-black"
            >How did you hear about us?*</label
          >
          <select
            id="heardAboutUs"
            v-model="form.heardAboutUs"
            class="w-full px-3 py-2 border rounded"
          >
            <option value="">Select an option</option>
            <option value="Facebook">Facebook</option>
            <option value="Instagram">Instagram</option>
            <option value="Google">Google</option>
            <option value="Google">Linkedin</option>
            <option value="Google">Website</option>
            <option value="Friend">Friend</option>
            <option value="Other">Others</option>
          </select>
          <div v-if="errors.heardAboutUs" class="text-red-500 text-sm">
            {{ errors.heardAboutUs }}
          </div>
        </div>
        <div class="mb-4">
          <label for="classPreference" class="block text-black"
            >Class Preference*</label
          >
          <select
            id="classPreference"
            v-model="form.classPreference"
            class="w-full px-3 py-2 border rounded"
          >
            <option value="">Select a class preference</option>
            <option v-for="day in classPref" :key="day" :value="day">
              {{ day }}
            </option>
          </select>
          <div v-if="errors.classPreference" class="text-red-500 text-sm">
            {{ errors.classPreference }}
          </div>
        </div>
        <button
          type="submit"
          :disabled="isLoading"
          class="mt-8 border border-primary-purple text-primary-purple px-8 py-2 rounded-[50px] hover:text-white hover:bg-primary-purple"
        >
          {{isLoading? 'Sending':'Submit'}}
        </button>
      </form>
    </div>
    <FooterSection />
  </section>
</template>

<script setup lang="ts">
import * as yup from "yup";
import FooterSection from "../HomeView/FooterSection.vue";
import Navbar from "../HomeView/Navbar.vue";
import Swal from "sweetalert2";
import { useRouter } from 'vue-router';
import { ref, onMounted, onUnmounted } from "vue";
import { CourseApi, FormApi } from "@/services";

const router = useRouter();
const coursePref =ref([])
const classPref =ref([])
const isLoading=ref(false)

const goBack = () => {
  router.go(-1); // Go back to the previous page in history
};

interface Form {
  fullName: string;
  email: string;
  phoneNumber: string;
  address: string;
  course: string;
  heardAboutUs: string;
  classPreference: string;
}

interface Errors {
  fullName?: string;
  email?: string;
  phoneNumber?: string;
  address?: string;
  course?: string;
  heardAboutUs?: string;
  classPreference?: string;
}

const form = ref<Form>({
  fullName: "",
  email: "",
  phoneNumber: "",
  address: "",
  course: "",
  heardAboutUs: "",
  classPreference: "",
});

const errors = ref<Errors>({});

const schema = yup.object().shape({
  fullName: yup.string().min(3).required("Full Name is required"),
  email: yup
    .string()
    .matches(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/)
    .required("Email is required"),
  phoneNumber: yup.string().required("Phone Number is required"),
  address: yup.string().required("Address is required"),
  course: yup.string().required("Course is required"),
  heardAboutUs: yup.string().required("This field is required"),
  classPreference: yup.string().required("Class Preference is required"),
});

onMounted(async () => {
  try {
    const response = await FormApi.coursePreferences(); // Replace with your API endpoint
    coursePref.value =await  response.courses
    classPref.value =await  response.class_preference
   
  } catch (error) {
    // console.error('Error fetching courses:', error);
    // Handle error gracefully, e.g., show an error message
  }
});

const submitForm = async () => {
  try {
    await schema.validate(form.value, { abortEarly: false });
    isLoading.value=true
  
    // Form is valid, reset errors
    errors.value = {};

    const formData = { ...form.value };

    const templateParams = {
      full_name: formData.fullName,
      email: formData.email,
      phone_number: formData.phoneNumber,
      address: formData.address,
      course: formData.course,
      hear_about_us: formData.heardAboutUs,
      class_preference: formData.classPreference,
    };

    const response = await FormApi.enrollNow(templateParams);
    isLoading.value=false
         
          form.value = {
            fullName: "",
            email: "",
            phoneNumber: "",
            address: "",
            course: "",
            heardAboutUs: "",
            classPreference: "",
          };
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.onmouseenter = Swal.stopTimer;
              toast.onmouseleave = Swal.resumeTimer;
            },
          });
          Toast.fire({
            icon: "success",
            title: "Form succesfully Submitted ",
          });
          
        
        (error:any) => {
    isLoading.value=false

          // console.error('FAILED...', error);
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.onmouseenter = Swal.stopTimer;
              toast.onmouseleave = Swal.resumeTimer;
            },
          });
          Toast.fire({
            icon: "error",
            title: "Failed to send the email. Please try again later. !",
          });
    isLoading.value=false

          // error.value = 'Failed to send the email. Please try again later.';
        }
      
  }
   catch (validationErrors: any) {
    // Set errors

    if (validationErrors && Array.isArray(validationErrors.inner)) {
            // Set errors
            validationErrors.inner.forEach(error => {
                errors.value[error.path] = error.message;
            });
        } else {
            console.error("Validation errors are not in expected format", validationErrors);
        }
    



    const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.onmouseenter = Swal.stopTimer;
              toast.onmouseleave = Swal.resumeTimer;
            },
          });
          Toast.fire({
            icon: "error",
            title: "Failed to send the email. Please try again later. !",
          });
  }
};
</script>

<style scoped></style>

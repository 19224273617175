<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.9105 10.7199H14.8205V3.5199C14.8205 1.8399 13.9105 1.4999 12.8005 2.7599L12.0005 3.6699L5.2305 11.3699C4.3005 12.4199 4.6905 13.2799 6.0905 13.2799H9.1805V20.4799C9.1805 22.1599 10.0905 22.4999 11.2005 21.2399L12.0005 20.3299L18.7705 12.6299C19.7005 11.5799 19.3105 10.7199 17.9105 10.7199Z"
      fill="#743DAA"
    />
  </svg>
</template>

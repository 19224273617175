<template>
    <div>
      <section class="flex flex-col lg:flex-row justify-center items-start min-h-screen">
        <div class="lg:w-1/2 w-full py-14 lg:px-24 px-10 text-[#4B4B4B]">
          <div class="mb-8">
            <router-link to="/">
              <img
                src="../../assets/camlds-logo.png"
                alt="logo"
                class="w-20 sm:w-40"
              />
            </router-link>
         
          </div>
  
          <div class="mb-8">
            <div class="title !text-2xl  mb-3">What We Offer</div>
            <div class="content">
              Fill in the application form and someone from our team will reach
              out to you shortly
            </div>
            <div class="avenir lg:hidden block font-bold text-base bg-primary-purple py-2 px-12 w-fit text-white rounded-[50px] mt-4"><a href="#apply">Apply</a></div>
          </div>
  
          <div class="mb-8">
            <div class="title !text-2xl mb-3">We are Hiring</div>
            <div class="avenir font-[350] text-base">
              <ul class="mt-5 space-y-5">
                <li class="flex gap-3 sm:ml-2">
                  <i
                    class="pi pi-check-circle mt-1"
                    style="font-size: 1rem; color: green"
                  ></i>
                  <span>
                    A highly skilled and dedicated team that is fun to work
                    with</span
                  >
                </li>
                <li class="flex gap-3 sm:ml-2">
                  <i
                    class="pi pi-check-circle mt-1"
                    style="font-size: 1rem; color: green"
                  ></i
                  ><span
                    >Remote work - We are a diverse and disturbed team that uses
                    Slack, Zoom, Notion, and other tools to stay connected.</span
                  >
                </li>
              </ul>
            </div>
          </div>
  
          <div class="mb-8">
            <div class="title !text-2xl mb-4">Our Values</div>
            <div class="mb-4">
              <b>Work in the open:</b> Operate with high integrity and choose
              what’s right over what’s easy. Be transparent as a company and with
              each other.
            </div>
            <div class="mb-4">
              <b>People are People:</b> Treat yourself, colleagues, and customers
              with dignity, empathy, and respect. Start from a presumption of
              positive intent.
            </div>
            <div class="mb-4">
              <b>Win and Grow Together Strive to be the best,</b> individually,
              and as a team. Support and encourage each other. Seek opportunities
              for growth.
            </div>
          </div>
        </div>
  
        <div class="lg:w-1/2 w-full bg-[#FDF7FE] py-12 lg:px-24 px-10 min-h-screen" id="apply">
          <div class="title !text-2xl mb-8 mt-6">Apply to become an instructor</div>
  
          <Form class="space-y-6" id="form" @submit="submitForm">
            <!-- Form fields -->
            <div class="flex flex-col sm:flex-row gap-6">
              <div class="flex flex-col w-full">
                <label for="fName">First Name</label>
                <input v-model="fName" type="text" name="fName" id="fName" placeholder="First Name" class="px-3 py-2 bg-white border rounded-md w-full">
                <div v-if="errors.fName" class="text-red-500 text-sm mb-2  w-fulltext-wrap">{{ errors.fName }}</div>
              </div>
              <div class="flex flex-col w-full">
                <label for="lName">Last Name</label>
                <Field v-model="lName" type="text" name="lName" id="lName" placeholder="Last Name" class="px-3 py-2 bg-white border rounded-md w-full" />
                <div v-if="errors.lName" class="text-red-500 text-sm mb-2  w-fulltext-wrap">{{ errors.lName }}</div>
              </div>
            </div>
  
            <div class="flex flex-col sm:flex-row gap-6">
              <div class="flex flex-col w-full">
                <label for="email">Email</label>
                <input v-model="email" type="email" name="email" id="email" placeholder="Email" class="px-3 py-2 bg-white border rounded-md w-full">
                <div v-if="errors.email" class="text-red-500 text-sm mb-2  w-fulltext-wrap">{{ errors.email }}</div>
            </div>
              <div class="flex flex-col w-full">
                <label for="phoneNumber">Phone Number</label>
                <input v-model="phoneNumber" type="text" name="phoneNumber" id="phoneNumber" placeholder="Phone Number" class="px-3 py-2 bg-white border rounded-md w-full">
                <div v-if="errors.phoneNumber" class="text-red-500 text-sm mb-2  w-fulltext-wrap">{{ errors.phoneNumber }}</div>
              </div>
            </div>
  
            <div class="flex flex-col sm:flex-row gap-6">
              <div class="flex flex-col w-full">
                <label for="gender">Gender</label>
                <select v-model="gender" id="gender" class="px-3 py-2 bg-white border rounded-md w-full">
                  <option value="male" selected disabled>Select Gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="female">Choose not to say</option>
                </select>
                <div v-if="errors.gender" class="text-red-500 text-sm mb-2  w-fulltext-wrap">{{ errors.gender }}</div>
              </div>
              <div class="flex flex-col w-full">
                <label for="course">Course you’ll like to Tutor</label>
                <input v-model="course" type="text" name="course" id="course" placeholder="e.g Frontend Development" class="px-3 py-2 bg-white border rounded-md w-full active:border-primary-purple">
                <div v-if="errors.course" class="text-red-500 text-sm mb-2  w-fulltext-wrap">{{ errors.course }}</div>
              </div>
            </div>
  
            <div class="flex flex-col sm:flex-row gap-6">
              <div class="flex flex-col w-full">
                <label for="cv">Resume/CV</label>
                <input type="file" id="cv" class="px-3 py-2 bg-white border rounded-md w-full" @change="handleFileChange('cv', $event)">
                <div v-if="errors.cv" class="text-red-500 text-sm mb-2  w-fulltext-wrap">{{ errors.cv }}</div>
              </div>
              <div class="flex flex-col w-full">
                <label for="letter">Cover Letter</label>
                <input type="file" id="letter" class="px-3 py-2 bg-white border rounded-md w-full" @change="handleFileChange('letter', $event)">
                <div v-if="errors.letter" class="text-red-500 text-sm mb-2  w-fulltext-wrap">{{ errors.letter }}</div>
              </div>
            </div>
  
            <div class="flex flex-col w-full">
              <label for="reason">Why do you want to tutor with us?</label>
              <textarea v-model="reason" id="reason" rows="8" class="px-3 py-2 bg-white border rounded-md w-full" placeholder="Reason ..."></textarea>
            </div>

           
            <!-- Submit button -->
            <div class="flex justify-start">
              <button type="submit" class="bg-[#531f87] hover:bg-primary-purple hover:scale-105 text-white font-light sm:py-4 sm:px-6 px-4 py-2.5 rounded-[50px]">Submit Application</button>
            </div>
  
          </Form>
        </div>
      </section>
  
      <FooterSection />
    </div>
  </template>
  
  <script setup lang="ts">
  import * as yup from 'yup';
  import { ref } from 'vue';
  import FooterSection from "../HomeView/FooterSection.vue";
import { Form, Field, ErrorMessage } from "vee-validate";
  
  const fName = ref('');
  const lName = ref('');
  const email = ref('');
  const phoneNumber = ref('');
  const gender = ref('');
  const course = ref('');
  const cv = ref<File | null>(null);
  const letter = ref<File | null>(null);
  const reason = ref('');
  
  const errors = ref<{
    [key: string]: string;
  }>({
    fName: '',
    lName: '',
    email: '',
    phoneNumber: '',
    gender: '',
    course: '',
    cv: '',
    letter: '',
    reason: '',
  });
  
  const schema = yup.object().shape({
    fName: yup.string().min(3).required('First Name is required'),
    lName: yup.string().min(3).required('Last Name is required'),
    email: yup.string().email('Email must be a valid email').required('Email is required'),
    phoneNumber: yup.string().required('Phone Number is required'),
    gender: yup.string().required('Gender is required'),
    course: yup.string().required('Course is required'),
    cv: yup.mixed().required('Resume/CV is required'),
    letter: yup.mixed().required('Cover Letter is required'),
  });
  
  const handleFileChange = (type: string, event: Event) => {
    const file = (event.target as HTMLInputElement).files?.[0] || null;
    if (type === 'cv') {
      cv.value = file;
    } else if (type === 'letter') {
      letter.value = file;
    }
  };
  
  const submitForm = async (event: Event) => {
    event.preventDefault();
  
    const formData = new FormData();
    formData.append('fName', fName.value);
    formData.append('lName', lName.value);
    formData.append('email', email.value);
    formData.append('phoneNumber', phoneNumber.value);
    formData.append('gender', gender.value);
    formData.append('course', course.value);
    formData.append('cv', cv.value as Blob);
    formData.append('letter', letter.value as Blob);
    formData.append('reason', reason.value);
  
    try {
      await schema.validate({
        fName: fName.value,
        lName: lName.value,
        email: email.value,
        phoneNumber: phoneNumber.value,
        gender: gender.value,
        course: course.value,
        cv: cv.value,
        letter: letter.value,
        reason: reason.value,
      }, { abortEarly: false });
  
      // If validation passes, handle form submission
      console.log('Form Submitted:', formData.values);
      // Reset errors
      errors.value = {
        fName: '',
        lName: '',
        email: '',
        phoneNumber: '',
        gender: '',
        course: '',
        cv: '',
        letter: '',
        reason: '',
      };
    } catch (validationErrors: any) {
      // If validation fails, set error messages
      validationErrors.inner.forEach((error: any) => {
        errors.value[error.path] = error.message;
      });
    }
  };
  </script>
  
  
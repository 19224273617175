import { AxiosResponse } from "axios";
import { axios$ } from "..";

interface Course {
  id: string;
  category: string[];
  image: string;
  title: string;
  duration: string;
  level: string;
  projects: string;
  rating: string;
}

interface CourseBig {
  title: string;
  image: string;
  description: string;
  overview: string;
  amount: string;
  whoCanEnroll: string[];
  benefits: string[];
  durationAndFormat: {
    duration: string;
    format: string;
  };
  toolsCovered: string[];
}

export async function allCategories() {
  try {
    const response = await axios$.get(`/categories/`);
    return response;
  } catch (error) {
    throw error;
  }
}

export async function allCourses() {
  try {
    const response: AxiosResponse<Course[]> = await axios$.get(`/courses/`);
    return response;
  } catch (error) {
    throw error;
  }
}

export async function singleCourse(id: string) {
  try {
    const response:AxiosResponse<CourseBig[]> = await axios$.get(`/courses/${id}/`);
    return response;
  } catch (error) {
    throw error;
  }
}
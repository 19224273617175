<template>
  <transition name="fade">
    <div v-if="isVisible" class="fixed inset-0 bg-black bg-opacity-65 flex items-center justify-center z-50 px-4">
      <div class="w-full max-w-xl bg-[#FDF7FE] rounded-lg overflow-y-scroll max-h-[80%] sm:min-h-[90%]">
        <div class="flex items-center justify-between p-4 border-b-2 border-gray-300 bg-white sticky top-0">
          <h5 class="exo font-semibold font-lg">Customised Request</h5>
          <button type="button" class="text-gray-500 hover:text-gray-700" @click="closeModal">
            <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
            </svg>
          </button>
        </div>

        <form class="mt-7 px-6" @submit.prevent="handleSubmit">
          <div class="mb-8">
            <label for="course" class="block mb-2">What Course do you want to learn?</label>
            <input id="course" v-model="formData.course" type="text" placeholder="e.g Frontend Development" class="py-2 w-full bg-white px-2 border border-gray-300 rounded-sm" />
          </div>

          <div class="flex flex-col sm:flex-row justify-between items-stretch gap-6 mb-8">
            <div class="flex-1">
              <div class="w-full">
                <label for="mode" class="block mb-2">Mode of Training</label>
                <select id="mode" v-model="formData.mode" class="py-2.5 w-full bg-white px-2 border border-gray-300 rounded-sm">
                  <option value="Online">Online</option>
                  <option value="Onsite">Onsite</option>
                  <option value="Hybrid">Hybrid</option>
                </select>
              </div>
            </div>
            <div class="flex-1">
              <div class="w-full">
                <label for="participants" class="block mb-2">No of Participants</label>
                <select id="participants" v-model="formData.participants" class="py-2.5 w-full bg-white px-2 border border-gray-300 rounded-sm">
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="8+">More than 8</option>
                </select>
              </div>
            </div>
            <div class="flex-1">
              <div class="w-full">
                <label for="startDate" class="block mb-2 w-full">Preferred Start Date</label>
                <input id="startDate" v-model="formData.startDate" type="date" class="py-2 w-full bg-white px-2 border border-gray-300 rounded-sm" />
              </div>
            </div>
          </div>
          

          <div class="mb-8">
            <label for="name" class="block mb-2">Name</label>
            <input id="name" v-model="formData.name" type="text" placeholder="Name" class="py-2 w-full bg-white px-2 border border-gray-300 rounded-sm" />
          </div>

          <div class="flex flex-col sm:flex-row justify-between items-center gap-6 mb-8">
            <div class="w-full sm:w-1/2">
              <label for="mobile" class="block mb-2">Mobile Number</label>
              <input id="mobile" v-model="formData.mobile" type="text" placeholder="Mobile Number" class="py-2 w-full bg-white px-2 border border-gray-300 rounded-sm" />
            </div>
            <div class="w-full sm:w-1/2">
              <label for="email" class="block mb-2">Email Address</label>
              <input id="email" v-model="formData.email" type="email" placeholder="Email Address" class="py-2 w-full bg-white px-2 border border-gray-300 rounded-sm" />
            </div>
          </div>

          <div class="mb-4">
            <label for="note" class="block mb-2">Note</label>
            <textarea id="note" v-model="formData.note" placeholder="Note" class="py-2 w-full bg-white px-2 border border-gray-300 rounded-sm"></textarea>
          </div>

          <div v-if="error" class="text-red-500 mt-4 text-center">{{ error }}</div>
          <div class="flex justify-center p-2 border-t mb-5">  
            <button
            :disabled="isLoading"
             type="submit" class="px-4 py-2 bg-primary-purple text-white rounded-[50px]">
             {{isLoading? 'Loading ..':'Get a Quote'}}
            </button>
          </div>
        </form>

      </div>
    </div>
  </transition>
</template>

<script setup>
import { ref, watch } from 'vue';
import * as yup from 'yup';
import Swal from 'sweetalert2'
import {  onMounted, onUnmounted } from "vue";
import { CourseApi, FormApi } from "@/services";

// Define validation schema
const schema = yup.object({
  course: yup.string().required('Course is required'),
  mode: yup.string().required('Mode is required'),
  participants: yup.string().required('Participants is required'),
  startDate: yup.date().required('Start Date is required'),
  name: yup.string().min(3).required('Name is required'),
  mobile: yup.string().required('Mobile Number is required'),
  email: yup.string().email('Email must be valid').required('Email is required'),
});

// Define reactive variables
const isVisible = ref(true);
const formData = ref({
  course: '',
  mode: 'Online',
  participants: '1',
  startDate: new Date().toISOString().split('T')[0],
  name: '',
  mobile: '',
  email: '',
  note: ''
});
let errorTimeout = null;
const error = ref('');
const isLoading=ref(false)

// Close modal function
const closeModal = () => {
  isVisible.value = false;
};

// Handle form submission
const handleSubmit = async () => {
  try {
    await schema.validate(formData.value, { abortEarly: false });
    isLoading.value=true
    
    const templateParams = {
      course: formData.value.course,
      mode_of_training: formData.value.mode,
      number_of_participants: formData.value.participants,
      preferred_start_date: formData.value.startDate,
      full_name: formData.value.name,
      phone_number: formData.value.mobile,
      email: formData.value.email,
      note: formData.value.note,
    };
    console.log(templateParams);
    const response = await FormApi.customizeTraining(templateParams);
  
        // console.log('SUCCESS!', response.status, response.text);
        const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.onmouseenter = Swal.stopTimer;
              toast.onmouseleave = Swal.resumeTimer;
            },
          });
          Toast.fire({
            icon: "success",
            title: "Message sent successfully",
          });
          isLoading.value=false
        closeModal();

    
  } catch (validationErrors) {
    isLoading.value=false
    const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.onmouseenter = Swal.stopTimer;
              toast.onmouseleave = Swal.resumeTimer;
            },
          });
          Toast.fire({
            icon: "error",
            title: "Failed to send the email. Please try again later. !",
          });
    error.value = validationErrors.errors;
    clearTimeout(errorTimeout);
    errorTimeout = setTimeout(() => {
      error.value = '';
    }, 3000); 
  }
};

// Watchers to clear errors on input change
Object.keys(formData.value).forEach(field => {
  watch(() => formData.value[field], () => {
    if (error.value) {
      error.value = '';
    }
  });
});
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>

<template>
  <section class="min-h-screen mb-10">
    <Navbar class="flex items-center justify-center" />
    <div
      class="md:mt-[200px] mt-[110px] max-w-[95vw] xl:max-w-[1280px] mx-auto"
    >
      <h1 class="title sm:!text-5xl text-lg mb-4">Popular Courses</h1>
      <div
        class="content sm:w-[460px] w-full text-wrap pr-6 mb-[52px] text-[#5B5B5B]"
      >
        These are the most popular courses among CAMLDS Courses learners
        worldwide in year 2024
      </div>

      <div
        class="h-[60px] lg:flex hidden bg-[#F2EBF8] rounded-md justify-start items-center text-[#5B5B5B] mb-8 max-w-[95vw] sm:min-w-[723px] xl:max-w-[1280px]"
      >
        <div
          class="px-14 py-4 cursor-pointer"
          :class="{
            'font-bold border-b-2  border-primary-purple':
              selectedCategory === 'All',
          }"
          @click="filterCourses('All')"
        >
          All
        </div>
        <div
        v-for="(category,index) in categories" :key="index"
          class="px-6 py-4 cursor-pointer"
          :class="{
            'font-bold border-b-2  border-primary-purple':
              selectedCategory == category,
          }"
          @click="filterCourses(category)"
        >
         {{category}}
        </div>
      
      </div>

      <div
        class="lg:hidden mb-8 max-w-[95vw] sm:max-w-[754px] xl:max-w-[1280px]"
      >
        <button
          @click="showDropdown = !showDropdown"
          class="w-full h-[54px] bg-[#F2EBF8] rounded-md text-[#5B5B5B] px-4 py-2 cursor-pointer flex items-center justify-between"
        >
          <span>{{ selectedCategory }}</span>
          <i
            :class="{
              'pi pi-chevron-down': !showDropdown,
              'pi pi-chevron-up': showDropdown,
            }"
          ></i>
        </button>
        <transition name="dropdown">
          <div
            v-if="showDropdown"
            class="w-full bg-[#F2EBF8] shadow-lg rounded-md mt-2"
          >
            <div
              class="px-4 py-2 cursor-pointer hover:bg-[#e2d6f1]"
              :class="{ 'font-bold': selectedCategory === 'All' }"
              @click="filterCourses('All')"
            >
              All
            </div>
            <div
            v-for="(category, index) in categories" :key="index"
              class="px-4 py-2 cursor-pointer hover:bg-[#e2d6f1]"
              :class="{ 'font-bold': selectedCategory == category }"
              @click="filterCourses(category)"
            >
              {{category}}
            </div>
            
          </div>
        </transition>
      </div>

      <div
        class="mt-8 h-full flex lg:flex-wrap gap-2 flex-nowrap lg:overflow-x-hidden overflow-x-auto !mx-auto justify-start text-center items-center"
      >
        <a v-for="course in filteredCourses" :key="course.title" class="course-item !min-w-[280px] block"
          :href="`/course/${course.id}`"
        >
          <img
            :src="course.image"
            :alt="course.title"
            class="w-full h-48 rounded-t-lg object-cover"
          />
          <div class="mt-4 px-3">
            <h3 class="text-base font-semibold h-[60px] text-left">
              {{ course.title }}
            </h3>
            <div class="flex justify-start gap-4 items-center">
              <p class="text-gray-500 mt-2">
                <i class="pi pi-clock" style="font-size: 0.75rem"></i>
                {{ course.duration }}
              </p>
              <p class="text-gray-500 mt-2 flex justify-start items-center">
                <LevelBarIconComp :type="course.level" /> {{ course.level }}
              </p>
            </div>
            <p class="text-gray-500 mt-2 text-left">
              <i class="pi pi-briefcase" style="font-size: 0.75rem"></i>
              {{ course.projects }} Projects
            </p>
            <p class="text-primary-orange mt-2 text-left">
              <i class="pi pi-star-fill" style="font-size: 0.75rem"></i>
              <i class="pi pi-star-fill" style="font-size: 0.75rem"></i>
              <i class="pi pi-star-fill" style="font-size: 0.75rem"></i>
              <i class="pi pi-star-fill" style="font-size: 0.75rem"></i>
              <i class="pi pi-star-half-fill" style="font-size: 0.75rem"></i>
              
            </p>
            <hr />
            <a :href="`/course/${course.id}`" class="flex justify-end items-center">
              <div
                class="flex justify-start items-center gap-2 py-2 px-4 w-fit my-3 bg-[#EDEAFF] text-purple-950 font-bold text-sm rounded-md"
              >
                <div class="">View Details</div>
                <i
                  class="pi pi-arrow-right"
                  style="font-size: 0.5rem"
                ></i>
              </div>
            </a>
          </div>
        </a>
      </div>
    </div>
  </section>
  <FooterSection />
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from "vue";
import Navbar from "../HomeView/Navbar.vue";
import LevelBarIconComp from "../../components/IconComp/LevelBarIconComp.vue";
import FooterSection from "../HomeView/FooterSection.vue";
import { CourseApi } from "@/services";


const selectedCategory = ref("All");
const showDropdown = ref(false);
const courses =ref<Course[]>([]);
const categories=ref<string[]>([''])

type CourseLevel = "Beginner" | "Intermediate" | "Advance";

interface Course {
  id: string;
  title: string;
  image: string;
  link: string;
  duration: string;
  level: CourseLevel;
  projects: string;
  rating: string;
  category: string;
}

onMounted(async () => {
  try {
    const response = await CourseApi.allCourses(); // Replace with your API endpoint
    courses.value =  response
   
    // Update courses data with fetched data
  } catch (error) {
    console.error('Error fetching courses:', error);

  }
});

onMounted(
  async ()=>{

    try{
      const res =await CourseApi.allCategories()
      categories.value=res

    }
    catch (error){
      console.error('Error fetching courses:', error);

    }
  }
)


//   {
//     title: "Data Science Foundation: The Python Techniques",
//     image:
//       "https://res.cloudinary.com/dp0whcqar/image/upload/v1719312747/8_ehipfx.png",
//     link: "https://bit.ly/10-weeks-DataScience",
//     duration: "8 weeks",
//     level: "Beginner",
//     projects: "4",
//     rating: "4.6",
//     category: "Data Science",
//   },
//   {
//     title: "Python: Introduction to Python for Beginners",
//     image:
//       "https://res.cloudinary.com/dp0whcqar/image/upload/v1719312744/1_zcoly6.png",
//     link: "https://bit.ly/10-weeks-DataScience",
//     duration: "4 weeks",
//     level: "Intermediate",
//     projects: "4",
//     rating: "4.9",
//     category: "Data Science",
//   },
//   {
//     title: "Django: Build anything Web Application with Python",
//     image:
//       "https://res.cloudinary.com/dp0whcqar/image/upload/v1719312746/7_x9ibol.png",
//     link: "https://bit.ly/10-weeks-DataScience",
//     duration: "4 weeks",
//     level: "Beginner",
//     projects: "4",
//     rating: "4.9",
//     category: "Web Development",
//   },
//   {
//     title: "Figma/Adobe XD: Jump Start Application Designs",
//     image:
//       "https://res.cloudinary.com/dp0whcqar/image/upload/v1719312746/2_xyfsqx.png",
//     link: "https://bit.ly/10-weeks-DataScience",
//     duration: "4 weeks",
//     level: "Advance",
//     projects: "2",
//     rating: "4.9",
//     category: "Design",
//   },
//   {
//     title: "Build Modern Static and Dynamic Web Sites",
//     image:
//       "https://res.cloudinary.com/dp0whcqar/image/upload/v1719312745/6_aauves.png",
//     link: "https://bit.ly/10-weeks-DataScience",
//     duration: "4 weeks",
//     level: "Advance",
//     projects: "2",
//     rating: "4.9",
//     category: "Web Development",
//   },
//   {
//     title: "How To Easily Create Website with Vue",
//     image:
//       "https://res.cloudinary.com/dp0whcqar/image/upload/v1719312750/9_jyz1pk.png",

//     link: "https://bit.ly/10-weeks-DataScience",
//     duration: "4 weeks",
//     level: "Intermediate",
//     projects: "3",
//     rating: "4.9",
//     category: "Web Development",
//   },
//   {
//     title: "Python Automation: Routine Task with Python",
//     image:
//       "https://res.cloudinary.com/dp0whcqar/image/upload/v1719312754/4_zl4ghj.png",

//     link: "https://bit.ly/10-weeks-DataScience",
//     duration: "4 weeks",
//     level: "Beginner",
//     projects: "2",
//     rating: "4.9",
//     category: "Data Science",
//   },
//   {
//     title: "Python for DataBase Experts: Manipulate & Transform Data",
//     image:
//       "https://res.cloudinary.com/dp0whcqar/image/upload/v1719312749/5_rj4kvl.png",

//     link: "https://bit.ly/10-weeks-DataScience",
//     duration: "4 weeks",
//     level: "Beginner",
//     projects: "2",
//     rating: "4.9",
//     category: "Data Science",
//   },
//   {
//     title: "Low Code / No Code Data Science  ",
//     image:
//       "https://res.cloudinary.com/dp0whcqar/image/upload/v1719312751/12_w029gm.png",
//     link: "https://bit.ly/10-weeks-DataScience",
//     duration: "4 weeks",
//     level: "Beginner",
//     projects: "2",
//     rating: "4.9",
//     category: "Gen",
//   },
//   {
//     title: "Generative Artificial Intelligence ",
//     image:
//       "https://res.cloudinary.com/dp0whcqar/image/upload/v1719312748/10_jtio20.png",
//     link: "https://bit.ly/10-weeks-DataScience",
//     duration: "4 weeks",
//     level: "Intermediate",
//     projects: "2",
//     rating: "4.9",
//     category: "Gen",
//   },
//   {
//     title: "Low Code / No Code Machine Learning  ",
//     image:
//       "https://res.cloudinary.com/dp0whcqar/image/upload/v1719312749/11_by3qsl.png",
//     link: "https://bit.ly/10-weeks-DataScience",
//     duration: "4 weeks",
//     level: "Beginner",
//     projects: "2",
//     rating: "4.9",
//     category: "Gen",
//   },
// ]);

const filteredCourses = computed(() => {
  if (selectedCategory.value === "All") {
    showDropdown.value = !showDropdown;
    return courses.value;
  } else {
    showDropdown.value = !showDropdown;
    return courses.value.filter(
      (course) => course.category.includes(selectedCategory.value)
    );
  }
});

const filterCourses = (category: string) => {
  selectedCategory.value = category;
};





// fetch(`${process.env.VUE_APP_Base_url}/courses/`, {
//   method: 'GET',
//   headers: {
//     'Integration-Token': `${process.env.VUE_APP_INTERGRATION_TOKEN}`,
//     'Access-Control-Allow-Origin': '*',
//     'Content-Type': 'application/json'
//   }
// })
// .then(response => {
//   if (!response.ok) {
//     throw new Error('Network response was not ok');
//   }
//   return response.json();
// })
// .then(data => {
//   console.log('Data:', data);
//   // Process your data here
// })
// .catch(error => {
//   console.error('Error fetching data:', error);
// });

</script>

<style scoped>
.course-item {
  margin: 10px;
  width: 280px !important;
  background: white;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}
.course-item:hover {
  transform: scale(1.05);
}





</style>

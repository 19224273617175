<template>
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.0609 10.7401L20.7009 9.16006C20.4409 8.86006 20.2309 8.30006 20.2309 7.90006V6.20006C20.2309 5.14006 19.3609 4.27006 18.3009 4.27006H16.6009C16.2109 4.27006 15.6409 4.06006 15.3409 3.80006L13.7609 2.44006C13.0709 1.85006 11.9409 1.85006 11.2409 2.44006L9.67086 3.81006C9.37086 4.06006 8.80086 4.27006 8.41086 4.27006H6.68086C5.62086 4.27006 4.75086 5.14006 4.75086 6.20006V7.91006C4.75086 8.30006 4.54086 8.86006 4.29086 9.16006L2.94086 10.7501C2.36086 11.4401 2.36086 12.5601 2.94086 13.2501L4.29086 14.8401C4.54086 15.1401 4.75086 15.7001 4.75086 16.0901V17.8001C4.75086 18.8601 5.62086 19.7301 6.68086 19.7301H8.41086C8.80086 19.7301 9.37086 19.9401 9.67086 20.2001L11.2509 21.5601C11.9409 22.1501 13.0709 22.1501 13.7709 21.5601L15.3509 20.2001C15.6509 19.9401 16.2109 19.7301 16.6109 19.7301H18.3109C19.3709 19.7301 20.2409 18.8601 20.2409 17.8001V16.1001C20.2409 15.7101 20.4509 15.1401 20.7109 14.8401L22.0709 13.2601C22.6509 12.5701 22.6509 11.4301 22.0609 10.7401ZM16.6609 10.1101L11.8309 14.9401C11.6909 15.0801 11.5009 15.1601 11.3009 15.1601C11.1009 15.1601 10.9109 15.0801 10.7709 14.9401L8.35086 12.5201C8.06086 12.2301 8.06086 11.7501 8.35086 11.4601C8.64086 11.1701 9.12086 11.1701 9.41086 11.4601L11.3009 13.3501L15.6009 9.05006C15.8909 8.76006 16.3709 8.76006 16.6609 9.05006C16.9509 9.34006 16.9509 9.82006 16.6609 10.1101Z"
      fill="#743DAA"
    />
  </svg>
</template>

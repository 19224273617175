<template>
  <section id="about">
    <div
    data-aos="fade-up" 
      class="max-w-[95vw] sm:max-w-[754px] xl:max-w-[1240px] w-full text-left mx-auto mb-[88px] bg-[#FDF5FF] border border-primary-purple rounded-b-md py-[36px] sm:px-[71px] px-8"
    >
      <div
        class="flex lg:flex-row flex-col-reverse justify-between items-center"
      >
        <div class="w-full sm:mt-0 mt-2 text-xl">
          <div class="title text-primary-purple mt-[14px] mb-5 font-bold  sm:leading-0 leading-[60px]">
            Unlock Your Future with CAMLDS
          </div>
          <div class=" text-[#7F6295] font-[350] content">
            CAMLDS in conjunction with industry partnership network (IPN),
            students have access to and possess a sense of belonging in Applied Machine Learning-Data Science
            and lifelong learning pathways that extend every industry and across
            the IT landscape through our well designed fast paced Accelerated
            Training, Incubation, Internship and Followership program
          </div>

        </div>

        <div class="w-full h-full">
          <img src="../../assets/IMG1.png" class="w-full h-full object-cover" />
        </div>
      </div>
    </div>
  </section>

  <!-- ========================================================================= -->
  <CoursesSection />

  <!-- ====================================================== -->

  <section id="about">
    <div
    data-aos="fade-up" 
      class="max-w-[95vw] sm:max-w-[754px] xl:max-w-[1240px] w-full mx-auto mb-[88px] border border-primary-purple rounded-md"
    >
      <div class="flex lg:flex-row flex-col">

      <div class="bg-[#F6EDFF] lg:w-1/2 w-full sm:rounded-md rounded-none sm:py-[92px] py-3 sm:px-[40px] px-5">
        <h2 class="title mb-6 text-[#3B0545]">Accelerate Your Career</h2>
        <div class="content text-[#7F6295] font-[350]">Our fast-paced Accelerated Training, Incubation, Internship, and Followership program is designed to launch your career in record time. Here’s what you can expect</div>
      </div>


      <div class="bg-[#743DAA] text-white lg:w-1/2 w-full rounded-none sm:py-[80px] py-8 sm:px-[30px] px-3 flex flex-col gap-8 !font-light">
        
        <div class="flex justify-start items-center gap-6 hover-item">
          <div class="sm:min-h-[56px] sm:min-w-[56px] min-h-[36px] min-w-[36px]   rounded-full flex justify-center items-center bg-white bg-circle"> <SparkIconComp /></div>
          <div class="w-full content font-[350]">Train with the Best: Intensive training sessions to fast-track your expertise and Certified Expert Instructions.</div>
        </div>

         <div class="flex justify-start items-center gap-6 hover-item">
          <div class="sm:min-h-[56px] sm:min-w-[56px] min-h-[36px] min-w-[36px]  rounded-full flex justify-center items-center bg-white bg-circle"> <SmallCircleIconComp /></div>
          <div class="w-full content font-[350]">Land a high paying job:Feeling stuck in your career? Unlock new opportunities with a CAMLDS course and secure a future you'll be proud of.</div>
        </div> 

        <div class="flex justify-start items-center gap-6 hover-item">
          <div class="sm:min-h-[56px] sm:min-w-[56px] min-h-[36px] min-w-[36px]  rounded-full flex justify-center items-center bg-white bg-circle"><TrophyIconComp /></div>
          <div class="w-full content font-[350]">Real-World Experience: Hands-on internships and incubation opportunities with leading companies.</div>
        </div>


        <div class="flex justify-start items-center gap-6 hover-item">
          <div class="sm:min-h-[56px] sm:min-w-[56px] min-h-[36px] min-w-[36px]  rounded-full flex justify-center items-center bg-white bg-circle"> <LoopIconComp /></div>
          <div class="w-full content font-[350]">Lifelong Learning: Continuous learning pathways to keep you at the forefront of innovation.</div>
        </div>

        <div class="flex justify-start items-center gap-6 hover-item">
          <div class="sm:min-h-[56px] sm:min-w-[56px] min-h-[36px] min-w-[36px]  rounded-full flex justify-center items-center bg-white bg-circle"> <TickIconComp /></div>
          <div class="w-full content font-[350]">Projects: work on projects as you learn and build a professional portfolio with us.</div>
        </div> 
      </div>
      </div>
      </div>
      </section>
      <!-- ++++++++++++===================++++++++++++++++++++++++++++++++++++++++++ -->

     <div class="bg-[#FFF2E1]"><section id="about">
        <div
        data-aos="fade-up" 
          class="max-w-[95vw] sm:max-w-[754px] xl:max-w-[1240px] w-full text-left mx-auto mb-[88px] rounded-md sm:py-[36px] sm:px-[71px] px-0 py-3"
        >
          <div
            class="flex lg:flex-row flex-col gap-4 justify-between items-center w-full p-0"
          >
            <div class="w-full sm:mt-0 mt-2 text-xl sm:p-2 pl-4">

              <div class="h-[104px] w-[104px]   rounded-full flex items-center justify-center bg-white"> <Badge2IconComp /> </div>
              <div class="title text-[#3B0545] mt-[14px] mb-5 font-bold  sm:leading-0 leading-[60px]">
                Our Vision
              </div>
              <div class="avenir text-[#7F6295] font-[350] text-base">
            <ul class="mt-5 space-y-5">
            <li class="flex justify-start items-start gap-3 sm:ml-2 ml-0">
              <i
                class="pi pi-check-circle  mt-[6px]"
                style="font-size: 1rem; color: green"
              ></i
              > <span> Empower Thousands: We're on a mission to cultivate hundreds of thousands of professionals in tech.</span>
            </li>
            <li class="flex justify-start items-start gap-3 sm:ml-2 ml-0">
              <i
                class="pi pi-check-circle mt-[6px]"
                style="font-size: 1rem; color: green"
              ></i
              ><span>Global Recognition: Position our students and West Africa as a premier hub for tech.</span>
            </li>
            <li class="flex justify-start items-start gap-3 sm:ml-2 ml-0">
              <i
                class="pi pi-check-circle mt-[6px]"
                style="font-size: 1rem; color: green"></i><span>Drive Innovation: Train students to professionally tackle Africa's challenges with cutting-edge solution. </span>
            </li>
          </ul> 
              </div>
    
            </div>
    
            <div class="w-full h-full">
              <img src="https://res.cloudinary.com/dp0whcqar/image/upload/v1719496659/About_Section_sni3lw.png" alt="Our team" class="!w-full !h-full object-cover rounded-md shadow-[10px] " />
            </div>
          </div>
        </div>
      </section>
    </div>
  <!-- =============================+++++++++++++++++++++++++++++======================= -->
  <section>
  
    <div
    data-aos="fade-up" 
      class="max-w-[95vw] sm:max-w-[754px] xl:max-w-[1240px] w-full text-left mx-auto mt-[56px] mb-[88px] bg-[#FDF5FF] border border-primary-purple rounded-md py-[36px] sm:px-[71px] px-8"
    >
      <div
        class="flex lg:flex-row-reverse flex-col-reverse justify-between items-center"
      >
        <div class="w-full sm:mt-0 mt-2 text-xl">
          <div class="title mt-[14px] mb-5 font-bold sm:leading-0 leading-[60px]">
            Training <span class="text-primary-orange">and</span> Learning <span class="bg-primary-orange text-white px-3 py-1 rounded-lg w-fit sm:text-[30px] text-xl">Track</span>
          </div>
          <div class="mt-0">

              <div class="flex-col flex w-full xl:gap-y-[6px] gap-y-[10px] xl:mt-[24px] sm:mt-[48px] mt-[26px]">
                <section v-for="(item, i) in tabs">
                  <div class="bg-inherit px-0 sm:px-[15px] xl:px-5">
                    <div
                      @click="handleDropDown(i)"
                      class="flex border-b border-border-grey cursor-pointer text-text-black xl:text-xl justify-between items-center text-lg exo font-medium min-h-[60px] xl:min-h-[80px] relative"
                    >
                      <h1 class="max-w-[80%]">{{ item.header }}</h1>
                      <i class="pi absolute top-8 right-4 " :class="['pi-fw', currentHeaderOpen && currentHeader === i ? 'pi-times' : 'pi-plus']"></i>

                    </div>
                    <transition name="slide-fade">
                    <div
                      v-if="currentHeaderOpen && currentHeader === i"
                      class="mt-2 xl:mt-4 text-[#737373] py-3 avenir content transition-all"
                    >
                      {{ item.content }}
                    </div>
                    </transition>
                  </div>
                </section>
              </div>
          </div>
        </div>

        <div class="min-w-[274px] !h-[229px]">
          <img src="../../assets/robot.png" class="w-full h-full" />
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts" setup>
import { onMounted, ref, watch } from 'vue';
import CoursesSection from './CoursesSection.vue';
import { SparkIconComp, TickIconComp,Badge2IconComp, SmallCircleIconComp, TrophyIconComp, LoopIconComp} from '../../components/IconComp/StrategyIconComp'

type Content = {
  header: string;
  content: string;
};



const handleDropDown = (index: number) => {
  if (index == currentHeader.value) {
    currentHeaderOpen.value = !currentHeaderOpen.value;
  } else {
    currentHeader.value = index;
    currentHeaderOpen.value = true;
  }
};



const currentHeader = ref<number>();
const currentHeaderOpen = ref<boolean>(false);
const tabs = ref<Content[]>([

  {
    header: "In person, online and a combination of both:",
    content:
      "The AML-DS full-time program begins with two weeks of hands-on online learning, which provides students with the foundational data skills they'll need to excel in the program. Students then participate in on-campus, project-based learning, which emphasizes collaboration and outcome-based skills development.",
  },
  {
    header: "Time for individual instruction/help:",
    content:
      "Students have access to support from the educator team throughout the day and during dedicated work periods. They also have access to a wide variety of additional training resources and materials available at all hours of the day and at intervals through dedicated mentors(Technical/Business). For the AML-DS professional development courses, typically about one-third to one-half of the class is dedicated to challenge work and project time in which students can work directly and individually with the senior AML-DS scientist instructors and/or the AML-DS scientist technical assistance. The mentor also holds weekly online office hours for students who have questions. Also offers a range of career services, including portfolio development, mock interviews with current industry professionals, résumé and job search workshops, and office tours of leading tech companies. The six-step iterative model of education prioritizes hands-on, project-based learning.",
  },
  {
    header: "Required student background:",
    content:
      'None, but individual pre-work programs are designed to bring student skills up to speed in advance of the program. No background knowledge is required but students are required to do about 20 hours of "pre-work" by the week day of class. Also offers a beginner Foundation Program option that students may take to skip the pre-work. Also to mention that no background knowledge is required for introductory-level courses.',
  },
  {
    header: "Placement/internship:",
    content:
      "Once you have gained mastery over the concepts and applications we will prepare you for interviews for the core AML-DS Role through our specially created models of interview preparations questions, we will teach students how to crack different types of questions. Subsequently, Students will have the opportunity to be placed on internship and have access to basic stipend from the 4th month.",
  },
]);

</script>

<style scoped>
.slide-fade-enter-active, .slide-fade-leave-active {
  transition: all 0.4s ease;
}
.slide-fade-enter-from, .slide-fade-leave-to {
  max-height: 0;
  opacity: 0;
}
.slide-fade-enter-to, .slide-fade-leave-from {
  max-height: 500px; 
  opacity: 1;
}


.transition-swipe-enter-active, .transition-swipe-leave-active {
  transition: transform 0.3s ease-out;
}
.transition-swipe-enter, .transition-swipe-leave-to {
  transform: translateX(100%);
}

.marquee {
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
}

.marquee-content {
  white-space: nowrap;
  animation: marquee 20s linear infinite;
}


.hover-item {
  transition: background-color 0.3s, padding 0.3s;
}

/* Define the hover styles */
.hover-item:hover {
  @apply bg-white text-primary-purple rounded-md;
  padding: 1rem 0.75rem; /* Adjust padding as needed */
}
.hover-item:hover  .bg-circle{
  @apply bg-primary-orange;
}


</style>

<template>
  <main class="overflow-clip w-full">
    <div class="min-h-screen" style="background: linear-gradient(180deg, #581699 50%, #581699 50%, #AB55FF 100%)">
    <Navbar />


    <!--=========================== Hero section =======================================-->
    <section
      class="flex xl:justify-center justify-center items-center  max-w-[95vw] sm:max-w-[754px] xl:max-w-[1280px] mx-auto lg:pb-4 pb-4 lg:mt-[110px] xl:mt-[145px] mt-[120px]"
    >
      <div class="flex flex-col items-center">
        <div
          class="z-30 xl:text-[50px] xl:w-[690px] lg:w-[663px] md:w-[463px] w-[363px] sm:text-[48px] text-white text-[32px] font-[700] exo text-center xl:mb-[59px] lg:mb-[49px] sm:mb-[49px] mb-8 sm:leading-[60px] leading-[40px]"
        >
          Build <span class="text-primary-orange">Real World</span> Projects
          On The Go
        </div>


        <div class="flex md:flex-row flex-col items-center justify-center gap-4 sm:mb-6 mb-10">
        <router-link to="/course" class=" z-30 cursor-pointer">
          <div
          class=" z-30 py-3 px-8  bg-primary-orange text-primary-purple rounded-[50px] text-lg  w-fit sm:mb-[74px]  select-none mx-auto  cursor-pointer hover:bg-orange-200"
        >
        Take a Course
        </div>
        </router-link>
        <div
        @click="showCustomisedModal = !showCustomisedModal"
          class=" z-30 py-3 px-8  text-white border border-white rounded-[50px] text-lg  w-fit sm:mb-[74px]  select-none mx-auto  cursor-pointer hover:bg-white hover:text-primary-purple"
        >
        Customize Learning
        </div>
        </div>


        <div class="z-30 border border-primary-orange rounded-[27px] py-[10px] sm:py-5  px-[16px] pl-[60px] w-fit relative mb-16 mx-auto ">
          <div class="sm:text-[16px] text-[11px] text-white flex items-center sm:w-[600px] w-[260px] sm:leading-2 leading-[8px]  gap-3 justify-center flex-wrap space-x-2">
            <span class="flex items-center"><span>Mentorship</span> </span> 
            <span class="flex items-center"><li class="pi pi-circle-fill text-[6px] mr-3"></li><span>Flexible Training</span>  </span>
            <span class="flex items-center"><li class="pi pi-circle-fill text-[6px] mr-3"></li><span>100% Internship </span>  </span>
            <span class="flex items-center"><li class="pi pi-circle-fill text-[6px] mr-3"></li><span>Placement Opportunity </span></span>
          </div>
          <BadgeIconComp class="absolute sm:-top-1 -top-1 sm:-left-5 -left-6 sm:w-20 sm:h-22  h-22 w-22" />
        </div>

          <div
          class="z-30 sm:my-3 my-8 grid sm:grid-cols-6 grid-cols-3 mx-auto sm:gap-14 gap-3 sm:w-full w-full justify-center items-center"
        >
          <div
          class="mx-auto"
            v-for="icon in heroIcons"
            :key="icon.title"
          >
            <!-- <img
            :src="icon.icon"
            :alt="icon.title"
              class="sm:h-[80px] sm:w-[80px] h-[25px] w-[25px]"
            /> -->
            <!-- <div class=" sm:text-[11px] text-[11px] font-[400] text-wrap text-center inter">
             
            </div> -->
            <img :src="icon.icon"
            class="!rounded-lg py-1.5 px-2 hover:scale-105 flex flex-col justify-center items-center bg-primary-purple shadow-md shadow-purple-800 sm:w-[116px] w-[70px] min-h-10 sm:min-h-24 text-white"
            :alt="icon.title"
            />
           
          </div>
        </div>
       
      </div>

      <img
        src="../../assets/Perspective Grid.png"
        alt="Hero Image"
        class="min-h-full animate-pulse w-full  inset-0 absolute top-0 mx-auto object-cover z-10"
      />
      
    </section>
    </div>
  
  <StrategySection />
  <BottomContent />
<TestimonialSection />
<GallerySection />
<FooterSection />

<AdsModal v-if="showModal" @close="showModal = false" />
<CustomiseCourses v-if="showCustomisedModal" />

</main>
</template>

<script lang="ts" setup>
import {BadgeIconComp} from '../../components/IconComp/HeroIconsComp/'
import 'swiper/swiper-bundle.css';
import { onMounted, ref,} from 'vue';
import AOS from 'aos';
import 'aos/dist/aos.css';
import StrategySection from './StrategySection.vue'
import BottomContent from './BottomContent.vue'
import FooterSection from './FooterSection.vue'
import Navbar from './Navbar.vue'
import AdsModal from '@/components/ModalComp/AdsModal.vue'
import TestimonialSection from './TestimonialSection.vue'
import GallerySection from './GallerySection.vue'
import CustomiseCourses from '../../components/ModalComp/CustomiseCourse.vue'

const showModal = ref(false);
const showCustomisedModal = ref(false);

const scrollVisible = ref(true);

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

onMounted(() => {
  setInterval(()=>{

    showModal.value = true;
  },20000)
});
// window.addEventListener("scroll", () => {
//   scrollVisible.value = window.scrollY > 0;
// });


const heroIcons =ref([
  {
  title: 'Generative AI',
  icon: 'https://res.cloudinary.com/dp0whcqar/image/upload/v1719332517/Generative_AI_kyw2qm.png',
},
  {
    title: 'Data Science/ ML',
    icon: 'https://res.cloudinary.com/dp0whcqar/image/upload/v1719332518/Data_Science_awdbaf.png',
},
{
  title: 'Fullstack',
  icon:'https://res.cloudinary.com/dp0whcqar/image/upload/v1719332516/fullstack_cea3ue.png',
},
  {
  title: 'UI/UX',
  icon:'https://res.cloudinary.com/dp0whcqar/image/upload/v1719332516/12_q70wfh.png',
},
  {
  title: 'Python',
  icon:'https://res.cloudinary.com/dp0whcqar/image/upload/v1719332517/Python_Automations_sy09os.png',
},
  {
  title: 'Cloud Computing',
  icon:'https://res.cloudinary.com/dp0whcqar/image/upload/v1719332516/Cloud_Computing_srhxnb.png',
},
])




onMounted(() => {
  AOS.init({
    duration: 2000, 
  });
});
</script>

<style scoped>
.transition-swipe-enter-active, .transition-swipe-leave-active {
  transition: transform 0.3s ease-out;
}
.transition-swipe-enter, .transition-swipe-leave-to {
  transform: translateX(100%);
}

.marquee {
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
}

.marquee-content {
  white-space: nowrap;
  animation: marquee 20s linear infinite;
}

.course-item {
  margin:10px;
  background: white;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}
.course-item:hover {
  transform: scale(1.05);
}

@media (max-width: 767px) {
  .p-carousel .p-carousel-viewport .p-carousel-viewport-content {
    display: flex !important;
  }
  .p-carousel .p-carousel-viewport .p-carousel-viewport-content .p-carousel-item {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}


@keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
</style>
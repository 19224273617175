
<template>
  <router-view />
</template>

<script setup lang="ts">


</script>

<style>
</style>

<template>
  <section class="bg-[#f5f6f6]">
    <div
      class="max-w-[95vw] sm:max-w-[754px] xl:max-w-[1240px] w-full mx-auto sm:pt-20 pt-8"
    >
      <div
        class="flex flex-col lg:flex-row justify-between items-start sm:items-start md:gap-20 gap-4 text-[#737373]"
      >
        <div class="mb-10 sm:mb-0">
          <router-link to="/">
            <img
              src="../../assets/camlds-logo.png"
              alt="logo"
              class="sm:w-40 w-20"
            />
          </router-link>
          <div class="w-full sm:w-[296px] mb-7 text-justify mt-8">
            (CAMLDS) envisions to build high-quality, culturally relevant and
            world-class Applied Machine Learning and Data Science learning
            experiences for every child and young person to positively impact
            lives through applied knowledge, research and project-based
            digitally transformed ecosystems.
          </div>
        </div>

        <div
          class="flex flex-wrap justify-start items-start sm:space-x-20 space-x-2"
        >
          <div
            class="flex flex-col justify-start items-start text-left sm:items-left sm:w-auto w-full"
          >
            <div class="text-[#06094E] text-2xl font-bold mb-[34px] exo">
              Company
            </div>
            <div class="mb-3 sm:mb-7"><a :href="`${$router}/#about`" >About</a></div>
            <div class="mb-3 sm:mb-7"><a href="#projects">Projects</a></div>
            <div class="mb-3 sm:mb-7"><router-link to="/blog" class="">Blog</router-link></div>
            <!-- <div class="mb-3 sm:mb-7"><router-link to="/campus" class="">Campus Network</router-link></div> -->
          </div>

          <div
            class="flex flex-col justify-start items-start sm:items-left sm:w-auto w-full"
          >
            <div class="text-[#06094E] text-2xl font-bold mb-[34px] exo">
              Support
            </div>
            <div
              class="mb-3 sm:mb-7 cursor-pointer"
            >
            <router-link to="/contact" class="">Contact</router-link>
            </div>
            <div class="mb-3 sm:mb-7">
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLSexV2kd6QcV5UJRa-44jsLmPDl5Jx06FF27UXyHJSB7iHL5Vg/viewform"
                target="_blank"
                >Become Volunteer</a
              >
            </div>
            <div
              @click="showLiveCode = !showLiveCode"
              class="mb-3 sm:mb-7 cursor-pointer"
            >
              Live Code Practice
            </div>
          </div>

          <div
            class="flex flex-col justify-start items-start sm:items-left sm:w-auto w-full"
          >
            <div class="text-[#06094E] text-2xl font-bold mb-[34px] exo">
              Get in touch
            </div>
            <div class="mb-3 sm:mb-7">West Africa</div>
            <div class="mb-3 sm:mb-7">
              Email: <span class="font-bold">support@camlds.com</span>
            </div>
            <div class="mb-3 sm:mb-7">
              Phone: <span class="font-bold">+234808-894-3064</span>
            </div>
            <!-- <div class="mb-3 sm:mb-7 font-extrabold" >
            <span class="font-bold flex items-center gap-3"><a href="https://wa.link/7d8mwb" target="_blank" class="underline"><i class="pi pi-whatsapp" style="color:green;font-size: 2rem;"></i> +234808-894-3064</a></span>
            </div> -->
          </div>
        </div>
      </div>

      <hr />
      <div class="flex justify-center items-center md:gap-24 gap-6 mt-2">
        <div class="py-7 exo text-center">
          &copy; {{ currentYear }} CAMLDS, Inc. All Rights Reserved
        </div>

        <div class="flex items-center gap-4">
          <a
            class="hover:scale-105"
            href="https://www.linkedin.com/company/camlds/"
            target="_blank"
          >
            <i class="pi pi-linkedin"></i>
          </a>

          <a
            class="hover:scale-105"
            href="https://www.instagram.com/camlds_/"
            target="_blank"
          >
            <i class="pi pi-instagram"></i>
          </a>
          <a
            class="hover:scale-105"
            href="https://web.facebook.com/camlds.org/"
            target="_blank"
          >
          <i class="fa-brands fa-facebook-f"></i>
          </a>
        </div>
      </div>
    </div>
  </section>

  <Contact v-if="showModal" />
  <LiveCode v-if="showLiveCode" />
  <button v-show="scrollVisible" class="fixed bottom-4 right-4 flex justify-start items-center gap-2 font-normal text-sm z-50 hover:scale-105">
    <div class="h-14 w-14 rounded-full flex justify-center items-center bg-green-500">
      <a href="https://wa.link/7d8mwb" target="_blank"><i class="pi pi-whatsapp mt-1 " style="color:white;font-size: 2rem;"></i></a>
    </div>
    </button>
</template>

<script setup lang="ts">
import Contact from "@/components/ModalComp/Contact.vue";
import LiveCode from "../../components/ModalComp/LiveCode.vue";
import { ref } from "vue";

const showModal = ref(false);
const showLiveCode = ref(false);
const scrollVisible = ref(true);

const $router = window.location.origin;

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

const currentYear = new Date().getFullYear();
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>

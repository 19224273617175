<template>
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.3591 13.2301C19.9891 13.2301 19.6791 12.9501 19.6391 12.5801C19.3991 10.3801 18.2191 8.40007 16.3991 7.14007C16.0691 6.91007 15.9891 6.46007 16.2191 6.13007C16.4491 5.80007 16.8991 5.72007 17.2291 5.95007C19.3991 7.46007 20.7991 9.82007 21.0891 12.4301C21.1291 12.8301 20.8391 13.1901 20.4391 13.2301C20.4091 13.2301 20.3891 13.2301 20.3591 13.2301Z"
      fill="#743DAA"
    />
    <path
      d="M3.73931 13.28C3.71931 13.28 3.68931 13.28 3.66931 13.28C3.26931 13.24 2.97931 12.88 3.01931 12.48C3.28931 9.86996 4.66931 7.50996 6.81931 5.98996C7.13931 5.75996 7.59931 5.83996 7.82931 6.15996C8.05931 6.48996 7.97931 6.93996 7.65931 7.16996C5.85931 8.44996 4.68931 10.43 4.46931 12.62C4.42931 13 4.10931 13.28 3.73931 13.28Z"
      fill="#743DAA"
    />
    <path
      d="M15.9906 21.6001C14.7606 22.1901 13.4406 22.4901 12.0606 22.4901C10.6206 22.4901 9.25059 22.1701 7.97059 21.5201C7.61059 21.3501 7.47059 20.9101 7.65059 20.5501C7.82059 20.1901 8.26059 20.0501 8.62059 20.2201C9.25059 20.5401 9.92059 20.7601 10.6006 20.8901C11.5206 21.0701 12.4606 21.0801 13.3806 20.9201C14.0606 20.8001 14.7306 20.5901 15.3506 20.2901C15.7206 20.1201 16.1606 20.2601 16.3206 20.6301C16.5006 20.9901 16.3606 21.4301 15.9906 21.6001Z"
      fill="#743DAA"
    />
    <path
      d="M12.0505 2.51001C10.5005 2.51001 9.23047 3.77001 9.23047 5.33001C9.23047 6.89001 10.4905 8.15001 12.0505 8.15001C13.6105 8.15001 14.8705 6.89001 14.8705 5.33001C14.8705 3.77001 13.6105 2.51001 12.0505 2.51001Z"
      fill="#743DAA"
    />
    <path
      d="M5.05047 14.3701C3.50047 14.3701 2.23047 15.6301 2.23047 17.1901C2.23047 18.7501 3.49047 20.0101 5.05047 20.0101C6.61047 20.0101 7.87047 18.7501 7.87047 17.1901C7.87047 15.6301 6.60047 14.3701 5.05047 14.3701Z"
      fill="#743DAA"
    />
    <path
      d="M18.9509 14.3701C17.4009 14.3701 16.1309 15.6301 16.1309 17.1901C16.1309 18.7501 17.3909 20.0101 18.9509 20.0101C20.5109 20.0101 21.7709 18.7501 21.7709 17.1901C21.7709 15.6301 20.5109 14.3701 18.9509 14.3701Z"
      fill="#743DAA"
    />
  </svg>
</template>

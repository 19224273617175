<template>
  <section class="py-18 bg-white mx-auto">
    <h2 class="title mb-3 text-center">You’re in safe hands</h2>
    <div class="content text-center font-normal">
      Here’s what our students are saying about us;
      <a
        href="https://www.google.com/maps/place/Center+for+Applied+Machine+Learning+and+Data+Science/@9.1100353,7.4249415,17z/data=!4m8!3m7!1s0x104ddf888ac6a5f3:0x49b977de064aee51!8m2!3d9.1100353!4d7.4275164!9m1!1b1!16s%2Fg%2F11rnbm3vjy?hl=en-US&entry=ttu"
        target="_blank"
        class="text-[#0066FF] underline"
        >Google Rating
      </a>
    </div>

    <Vue3Marquee :pauseOnHover=true :duration="100" >
    <div
      class="flex flex-nowrap overflow-x-auto no-scrollbar mt-[70px] gap-5 items-center"
    >
      <div
        v-for="(remark, index) in students"
        :key="index"
        class="flex-shrink-0 mb-4 border border-[#ECEFF3] shadow-sm shadow-primary-purple rounded-[16px] p-6 max-w-[343px] h-fit"
      >
        <div
          class="flex justify-start gap-3 items-center exo text-lg text-[#0C1118] font-bold mb-5"
        >
          <!-- <img src="../../assets/Avatar Image.png" /> -->
          <div
          :style="{ backgroundColor: colors[index % colors.length] }"
          class="w-12 h-12 rounded-full flex items-center justify-center text-white border shadow-md hover:shadow-none"
        >
          {{ getInitial(remark.name) }}
        </div>
          <div>{{ remark.name }}</div>
          <TickIconComp />
        </div>
        <div class="text-[#454D59] font-normal text-sm exo">
          " {{ remark.comment }} "
        </div>
      </div>
    </div>
    </Vue3Marquee>
  </section>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { TickIconComp } from "../../components/IconComp/StrategyIconComp";
import { computed } from "vue";
import { Vue3Marquee } from 'vue3-marquee'
const students = ref([
  {
    name: "Chukwudi Ogbu",
    image: "https://via.placeholder.com/150",
    comment:
      "Reflecting on my journey with the Centre for Applied Machine Learning and Data Science, I have found it incredibly rewarding, providing invaluable insights into the realm of data science. I wholeheartedly recommend it to those eager to enrich their skill set and advance their IT proficiency in machine learning and data science amidst this rapidly evolving age.",
  },
  {
    name: "Ezekiel Awodi",
    image: "",
    comment:
      "My experience at the Centre for Applied Machine Learning and Data Science was transformative, equipping me with the skills and expertise to succeed in this exciting and rapidly evolving field. I would highly recommend it to anyone looking to advance their skills and knowledge in machine learning and data science.",
  },
  {
    name: "Sunday Meliga",
    image: "",
    comment:
      "With what I’ve seen and experienced so far, also with the students and tutor relationship couples with the relax ambience of the enviroment and then the  state of the art equipments;”CALMDS” is definitely the right place to be.",
  },
  {
    name: "Ben Dominic",
    image: "",
    comment:
      "So far, my experience of learning data science here has been very nice. The facilitators are patient with us and they explain every detail to us in a way we will understand. I recommend for anyone who wishes to learn Data science or Web Development",
  },
  {
    name: "Isobel Okosi",
    image: "",
    comment:
      "Taking they Data science class, collins and Joy makes it easier, understandable and interesting",
  },
  {
    name: "Josh Tech",
    image: "",
    comment:
      "Very serene and friendly learning environment, I studied data science and so far my experience has been great. They have a good learning structure.",
  },
  {
    name: "Ahamefule Chukwuka",
    image: "",
    comment:
      "Enrolled for data science training tho my training is still going.Qualified instructors ,Good learning environment.",
  },
  {
    name: "Jeremy Onoja",
    image: "",
    comment:
      "it has been an exciting journey in data science class with CAMLDS their facilitators are really great and willing to help at all times",
  },
]);
const colors = [
  "#FFDDC1", "#FFD3B4", "#FFAAA6", "#FF8C94",
  "#FFC4E1", "#FFEBE5", "#FFF9C4", "#D0F4DE"
];
const getInitial = (name: string) => name.charAt(0).toUpperCase();

</script>

<style>
.initial-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: #eceff3;
  border-radius: 50%;
  font-size: 18px;
  font-weight: bold;
  color: #0c1118;
}</style>

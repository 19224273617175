import { AxiosResponse } from "axios";
import { axios$ } from "..";

interface Course {
  id: string;
  category: string[];
  image: string;
  title: string;
  duration: string;
  level: string;
  projects: string;
  rating: string;
}




export async function contactUs(data: {
    fname: string; 
    lname: string;
    email: string; 
    phone_number: string; 
    message: string;
  }) {
  try {
    const response = await axios$.post(`contact-us/`,data);
    return response;
  } catch (error) {
    throw error;
  }
}

export async function enrollNow(data:any) {
  try {
    const response: AxiosResponse<Course[]> = await axios$.post(`enroll-now/ `,data);
    return response;
  } catch (error) {
    throw error;
  }
}

export async function customizeTraining(data: any) {
  try {
    const response = await axios$.post(`/customize-training/`, data);
    return response;
  } catch (error) {
    throw error;
  }
}
export async function coursePreferences() {
  try {
    const response = await axios$.get(`/course-preferences/`);
    return response;
  } catch (error) {
    throw error;
  }
}
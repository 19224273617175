<template>
  <svg
    width="49"
    height="49"
    viewBox="0 0 49 49"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M24.4999 46.5498C36.6775 46.5498 46.5498 36.6775 46.5498 24.4999C46.5498 12.3222 36.6775 2.44999 24.4999 2.44999C12.3222 2.44999 2.44999 12.3222 2.44999 24.4999C2.44999 36.6775 12.3222 46.5498 24.4999 46.5498ZM24.4999 48.9998C38.0312 48.9998 48.9998 38.0312 48.9998 24.4999C48.9998 10.9686 38.0312 0 24.4999 0C10.9686 0 0 10.9686 0 24.4999C0 38.0312 10.9686 48.9998 24.4999 48.9998Z"
      fill="white"
    />
    <path
      d="M9.7998 38.7468C9.7998 37.4814 10.7455 36.412 12.0048 36.2724C21.4556 35.2262 27.5867 35.3205 37.0167 36.2956C37.4876 36.3451 37.9339 36.5306 38.3011 36.8295C38.6683 37.1284 38.9405 37.5278 39.0845 37.9789C39.2284 38.43 39.2379 38.9132 39.1117 39.3696C38.9856 39.826 38.7292 40.2357 38.374 40.5488C27.2449 50.2495 20.8848 50.116 10.5838 40.5586C10.0816 40.0931 9.7998 39.4304 9.7998 38.7468Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M36.8909 37.513C27.5357 36.5453 21.4928 36.4547 12.1387 37.4898C11.8308 37.5257 11.5469 37.6738 11.3415 37.906C11.136 38.1381 11.0234 38.4379 11.0252 38.7478C11.0252 39.0982 11.171 39.4302 11.4172 39.6605C16.523 44.3963 20.3915 46.5364 24.1731 46.5498C27.9681 46.5633 32.0449 44.4404 37.5696 39.6262C37.7451 39.4699 37.8715 39.2661 37.9333 39.0394C37.9951 38.8127 37.9898 38.573 37.9179 38.3493C37.846 38.1256 37.7107 37.9276 37.5284 37.7793C37.3461 37.6311 37.1246 37.5378 36.8909 37.513ZM11.8704 35.0545C21.4205 33.9973 27.641 34.0929 37.1445 35.0765C37.8529 35.1505 38.5244 35.4294 39.0768 35.8791C39.6291 36.3288 40.0384 36.9297 40.2544 37.6085C40.4705 38.2872 40.484 39.0141 40.2932 39.7004C40.1025 40.3866 39.7158 41.0024 39.1805 41.4722C33.5761 46.3575 28.9089 49.0182 24.1657 48.9998C19.409 48.9827 14.9476 46.2767 9.75242 41.4563C9.38046 41.1098 9.08393 40.6904 8.88138 40.2242C8.67883 39.7579 8.5746 39.2549 8.5752 38.7466C8.57342 37.8333 8.90865 36.9514 9.51669 36.2699C10.1247 35.5884 10.9628 35.1564 11.8704 35.0545Z"
      fill="white"
    />
    <path
      d="M34.3001 19.6C34.3001 22.1991 33.2676 24.6918 31.4298 26.5296C29.5919 28.3675 27.0993 29.4 24.5001 29.4C21.901 29.4 19.4084 28.3675 17.5705 26.5296C15.7327 24.6918 14.7002 22.1991 14.7002 19.6C14.7002 17.0009 15.7327 14.5082 17.5705 12.6704C19.4084 10.8325 21.901 9.80005 24.5001 9.80005C27.0993 9.80005 29.5919 10.8325 31.4298 12.6704C33.2676 14.5082 34.3001 17.0009 34.3001 19.6Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M24.5001 26.95C26.4495 26.95 28.319 26.1756 29.6974 24.7972C31.0757 23.4188 31.8501 21.5493 31.8501 19.6C31.8501 17.6507 31.0757 15.7812 29.6974 14.4028C28.319 13.0244 26.4495 12.25 24.5001 12.25C22.5508 12.25 20.6813 13.0244 19.3029 14.4028C17.9246 15.7812 17.1502 17.6507 17.1502 19.6C17.1502 21.5493 17.9246 23.4188 19.3029 24.7972C20.6813 26.1756 22.5508 26.95 24.5001 26.95ZM24.5001 29.4C27.0993 29.4 29.5919 28.3675 31.4298 26.5296C33.2676 24.6918 34.3001 22.1991 34.3001 19.6C34.3001 17.0009 33.2676 14.5082 31.4298 12.6704C29.5919 10.8325 27.0993 9.80005 24.5001 9.80005C21.901 9.80005 19.4084 10.8325 17.5705 12.6704C15.7327 14.5082 14.7002 17.0009 14.7002 19.6C14.7002 22.1991 15.7327 24.6918 17.5705 26.5296C19.4084 28.3675 21.901 29.4 24.5001 29.4Z"
      fill="white"
    />
  </svg>
</template>

<template>
    <div>
      <button v-if="showSharebutton" @click="sharePost" class="bg-primary-purple text-white px-4 py-2 rounded-[30px] hover:bg-purple-900">
        Share
      </button>
      <div v-if="showFallbackLinks" class="mt-4 flex items-center gap-3">
        <a :href="facebookShareUrl" target="_blank" class="block text-primary-purple"><i class="fa-brands fa-facebook"></i></a>
        <a :href="twitterShareUrl" target="_blank" class="block text-primary-purple"><i class="fa-brands fa-x-twitter"></i></a>
        <a :href="emailShareUrl" class="block text-primary-purple"><i class="fa-solid fa-envelope"></i></a>
      </div>
    </div>
  </template>
  
  <script setup lang="ts">
  import { ref, computed } from 'vue';
  
  const props = defineProps({
    postTitle: {
      type: String,
      required: true,
    },
    postUrl: {
      type: String,
      required: true,
    },
  });
  
  const showFallbackLinks = ref(false);
  const showSharebutton = ref(true);
  
  const facebookShareUrl = computed(() => {
    return `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(props.postUrl)}`;
  });
  
  const twitterShareUrl = computed(() => {
    return `https://twitter.com/intent/tweet?url=${encodeURIComponent(props.postUrl)}&text=${encodeURIComponent(props.postTitle)}`;
  });
  
  const emailShareUrl = computed(() => {
    return `mailto:?subject=${encodeURIComponent(props.postTitle)}&body=Check out this post: ${encodeURIComponent(props.postUrl)}`;
  });
  
  const sharePost = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: props.postTitle,
          url: props.postUrl,
          
        });
        
      } catch (error) {
        console.error('Error sharing:', error);
      }
    } else {
      showFallbackLinks.value = true;
      showSharebutton.value = true;
    }
  };
  </script>
  
  <style scoped>
  /* Add any custom styles here */
  </style>
  
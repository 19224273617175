<template>
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.3568 3.72253C16.7119 3.72263 16.9999 3.4349 16.9999 3.07986C16.9999 2.72483 16.7122 2.43692 16.3572 2.43682L5.21433 2.43359C4.85929 2.43349 4.57129 2.72123 4.57129 3.07626C4.57129 3.43131 4.85892 3.71921 5.21396 3.71931L16.3568 3.72253ZM10.6983 19.5695L10.7856 19.5755C11.111 19.5755 11.38 19.3336 11.4226 19.0198L11.4284 18.9326L11.4276 7.20258L14.6177 10.3914C14.8459 10.6196 15.2031 10.6403 15.4548 10.4536L15.5269 10.3913C15.755 10.1631 15.7758 9.80591 15.589 9.55426L15.5268 9.48217L11.243 5.19939C11.0149 4.97131 10.658 4.95048 10.4064 5.13696L10.3343 5.19913L6.0456 9.48191C5.79438 9.7328 5.7941 10.1399 6.04498 10.3911C6.27305 10.6194 6.63018 10.6404 6.88197 10.4539L6.95412 10.3917L10.1419 7.20858L10.1427 18.9326C10.1427 19.2581 10.3846 19.527 10.6983 19.5695Z"
        fill="#101727"
      />
    </svg>
  </template>
  
  <script setup></script>
  
<template>
  <svg
    width="73"
    height="72"
    viewBox="0 0 73 72"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M64.2488 55.4103L59.2988 56.5803C58.1888 56.8503 57.3188 57.6903 57.0788 58.8003L56.0288 63.2103C55.4588 65.6103 52.3988 66.3603 50.8088 64.4703L41.8388 54.1503C41.1188 53.3103 41.5088 51.9903 42.5888 51.7203C47.8988 50.4303 52.6688 47.4603 56.1788 43.2303C56.7488 42.5403 57.7688 42.4503 58.3988 43.0803L65.0588 49.7403C67.3388 52.0203 66.5288 54.8703 64.2488 55.4103Z"
      fill="#581699"
    />
    <path
      d="M8.59756 55.4103L13.5476 56.5803C14.6576 56.8503 15.5276 57.6903 15.7676 58.8003L16.8176 63.2103C17.3876 65.6103 20.4476 66.3603 22.0376 64.4703L31.0076 54.1503C31.7276 53.3103 31.3376 51.9903 30.2576 51.7203C24.9476 50.4303 20.1776 47.4603 16.6676 43.2303C16.0976 42.5403 15.0776 42.4503 14.4476 43.0803L7.78756 49.7403C5.50756 52.0203 6.31756 54.8703 8.59756 55.4103Z"
      fill="#581699"
    />
    <path
      d="M36.4961 5.99951C24.8861 5.99951 15.4961 15.3895 15.4961 26.9995C15.4961 31.3495 16.7861 35.3395 19.0061 38.6695C22.2461 43.4695 27.3761 46.8595 33.3461 47.7295C34.3661 47.9095 35.4161 47.9995 36.4961 47.9995C37.5761 47.9995 38.6261 47.9095 39.6461 47.7295C45.6161 46.8595 50.7461 43.4695 53.9861 38.6695C56.2061 35.3395 57.4961 31.3495 57.4961 26.9995C57.4961 15.3895 48.1061 5.99951 36.4961 5.99951ZM45.6761 26.3395L43.1861 28.8295C42.7661 29.2495 42.5261 30.0595 42.6761 30.6595L43.3961 33.7495C43.9661 36.1795 42.6761 37.1395 40.5161 35.8495L37.5161 34.0795C36.9761 33.7495 36.0761 33.7495 35.5361 34.0795L32.5361 35.8495C30.3761 37.1095 29.0861 36.1795 29.6561 33.7495L30.3761 30.6595C30.4961 30.0895 30.2861 29.2495 29.8661 28.8295L27.3161 26.3395C25.8461 24.8695 26.3261 23.3995 28.3661 23.0695L31.5761 22.5295C32.1161 22.4395 32.7461 21.9595 32.9861 21.4795L34.7561 17.9395C35.7161 16.0195 37.2761 16.0195 38.2361 17.9395L40.0061 21.4795C40.2461 21.9595 40.8761 22.4395 41.4461 22.5295L44.6561 23.0695C46.6661 23.3995 47.1461 24.8695 45.6761 26.3395Z"
      fill="#581699"
    />
  </svg>
</template>

<template>
    <Navbar />
    <section
      v-if="singlePost && !isLoading"
      class="sm:mb-[40px] mb-[] sm:mt-[200px] mt-[128px] md:mt-[138px] sm:max-w-[604px] max-w-[367px] lg:max-w-[825px] w-full md:mx-auto mx-auto xl:min-h-[349px] min-h-[318px]"
    >
      <div class="flex justify-between items-center">
        <div class="flex gap-2 justify-start items-center">
          <CloseIconComp2
            @click="$router.go(-1)"
            class="p-1 bg-primary-grey rounded-full cursor-pointer sm:w-[26px] sm:h-[26px] hover:shadow-md hover:scale-105"
          />
          <div class="text-[12px] text-text-dull-grey font-medium">
            {{
              new Date(singlePost.date).toLocaleDateString("en-US", { day: "2-digit", month: "short", year: "numeric" })
            }}
          </div>
        </div>
        <div class="flex gap-2 text-xs sm:text-base">
         
           <SpeakOutIconComp
            v-if="!isPlaying"
            type="play"
            @click="readBlogPost(singlePost.content)"
            class="cursor-pointer rounded-full p-2 sm:!w-35 sm:!h-35 hover:shadow-lg hover:scale-105"
          />
          <SpeakOutIconComp
            v-else
            type="pause"
            @click="pauseSpeech()"
            class="cursor-pointer rounded-full p-2 sm:!w-35 sm:!h-35 hover:shadow-lg hover:scale-105"
          />
        </div>
      </div>
  
      <div
        class="font-bold mb-6 xl:mb-10 text-primary-blue mt-4 xl:mt-8 sm:max-w-[604px] max-w-[367px] lg:max-w-[825px] w-full hero-gradient block"
      >
        <div class="!text-[20px] xl:!text-[40px] exo">{{ singlePost.title }}</div>
      </div>
  
      <div class="flex justify-start items-center gap-2 mb-4">
        <div
          class="w-5 h-5 xl:w-10 xl:h-10 rounded-full shadow-md text-center flex justify-center items-center"
        >
          <img
            class="size-5"
            src="../../../public/img/icons/android-icon-192x192.png"
            alt="C"
          />
        </div>
        <div class="text-xs">CAMLDS Team</div>
      </div>
      <img
        :src="singlePost.img_link"
        class="!mb-8 sm:max-w-[604px] max-w-[367px] lg:max-w-[825px] w-full mx-auto bg-primary-landing-grey xl:min-h-[349px] min-h-[318px] rounded-[8px] xl:rounded-[12px] flex flex-col items-center justify-center"
      />
  
      <div v-html="singlePost.content" class="!blog-content mt-4 text-justify !font-normal text-[#4B4B4B] px-2"></div>
  
      <div class="flex justify-start items-center gap-2 text-xs sm:text-base sm:mt-10 mt-5">
        <div class="flex justify-start items-center gap-2">
          <div class="text-text-grey">{{ formatClapCount(singlePost.clap_count) }}</div>
          <ClapIconComp v-if="!Clapped" class="cursor-pointer sm:h-6 sm:w-6 h-4 w-4 sm:-mt-2 -mt-1" @click="clap" />
          <img
            v-if="Clapped"
            class="sm:h-6 sm:w-6 h-4 w-4 sm:-mt-2 -mt-1"
            src="https://res.cloudinary.com/duw4jtxls/image/upload/v1715253991/unnamed_p92u1l.gif"
          />
        </div>
        <SocialMediaShare :postUrl="url" :postTitle="singlePost.title" />
  
      </div>
    </section>
  
    <!-- <section>
      <div class="flex justify-center items-center gap-2 mt-10">
        <div class="text-text-grey">Share this post</div>
  
        <ArrowRightIconComp class="cursor-pointer sm:h-6 sm:w-6 h-4 w-4" />
      </div>
    </section> -->
    <hr class="w-full my-4 h-1" />
    <!-- section to display 3 newest blog post title -->
    <section v-if="!isLoading" class="mt-10 sm:max-w-[604px] max-w-[367px] lg:max-w-[825px] mx-auto">
      <div class="flex justify-start items-center gap-2 mb-4">
        <div v-if="singlePost" class="text-primary-blue font-bold">Related Posts</div>
      </div>
  
      <div class="flex md:overflow-x-hidden overflow-x-auto gap-3">
        <div
          v-for="post in recentPosts"
          :key="post.post_id"
          class="!min-h-[375px] w-[389px] rounded-[10px] sm:mx-auto mx-2 sm:mb-10 mb-4"
        >
          <img :src="post?.img_link" class="!h-[192px] bg-gray-200 w-full rounded-[10px]" />
  
          <div class="my-4">
            <div class="text-[12px] text-text-dull-grey font-medium">
              {{ new Date(post.date).toLocaleDateString("en-US", { day: "2-digit", month: "short", year: "numeric" }) }}
            </div>
          </div>
  
          <router-link :to="'/blog/post/' + post.post_id" @click="reloadAfterDelay"
            ><div class="font-bold text-[18px] w-[337px] max-h-[44px] mb-3 text-primary-blue">
              {{ post?.title.length > 60 ? post?.title.substring(0, 60) + "..." : post.title }}
            </div></router-link
          >
  
          <div
            v-html="post?.content.length > 10 ? post?.content.substring(0, 120) + '...' : post.content"
            class="font-normal blog-content w-[337px] mb-3 text-text-grey"
          ></div>
  
          <div class="text-xs flex justify-between items-center gap-2">
            <div class="flex justify-start items-center gap-4">
              <div class="w-5 h-5 rounded-full bg-primary-landing-grey text-center flex justify-center items-center">
                <img
                  src="../../../public/img/icons/android-icon-192x192.png"
                  alt="C"
                />
              </div>
              <div class="text-xs">Queryfier Team</div>
  
              <div class="flex justify-start items-center gap-2">
                <div class="text-text-grey">{{ post.clap_count }}</div>
                <ClapIconComp class="sm:h-6 sm:w-6 h-4 w-4 sm:-mt-2 -mt-1" title="Clap" />
              </div>
            </div>
  
            <router-link :to="'/blog/post/' + post.post_id" @click="reloadAfterDelay">
              <ArrowRightIconComp
            /></router-link>
          </div>
        </div>
      </div>
    </section>
  
    <div v-if="!singlePost && isLoading" class="h-screen flex justify-center items-center"><div class="loader"></div>  </div>
  
    <div
      v-if="apiCallFailed"
      class="sm:!mt-[393px] !mt-[528px] md:!mt-[938px]"
      text="Unable to load blog posts right now"
      @click="reload"
    ></div>
  
    <button
      v-show="scrollVisible"
      @click="scrollToTop"
      class="fixed bottom-4 right-4 flex justify-start items-center gap-2 font-normal text-sm"
    >
      <div class="h-12 w-12 rounded-full flex justify-center items-center bg-[#DCDCDC]">
        <ArrowUpIconComp />
      </div>
    </button>
    <FooterSection />
  </template>
  
  <script setup lang="ts">
 import Navbar from "../HomeView/Navbar.vue";
import FooterSection from "../HomeView/FooterSection.vue";
import ClapIconComp from '@/components/IconComp/ClapIconComp.vue'
import SpeakOutIconComp from '@/components/IconComp/SpeakOutIconComp.vue'
import ArrowUpIconComp from '@/components/IconComp/ArrowUpIconComp.vue'
import ArrowRightIconComp from '@/components/IconComp/ArrowRightIconComp.vue'
import CloseIconComp2 from '@/components/IconComp/CloseIconComp2.vue'
import SocialMediaShare from '@/components/SocialMediaShare.vue'
import { useHead } from '@vueuse/head'
  import { useRoute } from "vue-router";
  import { onMounted, onUnmounted, ref, watch } from "vue";
  import { BlogApi} from "@/services";
import { reactive } from "vue";
//   import { useMessageStore } from "@/stores";
//   import LoadingState from "@/views/BlogView/SingleBlogPostView/LoadingState.vue";
//   import RetryLoaderComp from "@/components/RetryLoaderComp";
//   import SocialMediaShare from "@/components/SocialMediaShareComp"
  
  const url =ref(window.location.href)
  const route = useRoute();
  const Clapped = ref(false);
  const singlePost = ref<any>(null);
  const recentPosts = ref<any>(null);
  const postId = ref<any>(route.params.post_id);
  const isLoading = ref(false);
  const isPlaying = ref(false);
  const apiCallFailed = ref(false);
  const scrollVisible = ref(false);



  
  window.addEventListener("scroll", () => {
    scrollVisible.value = window.scrollY > 0;
  });
  
  function reload() {
    window.location.reload();
  }
  
  function reloadAfterDelay() {
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }
  
  const clap = async () => {
    Clapped.value = true;
  
    try {
      setTimeout(async () => {
        const blogReq:any = await BlogApi.clap(postId.value);
                 singlePost.value.clap_count =  blogReq.clap_count;      
        Clapped.value = false;
      }, 1000);
    } catch (error) {
    //   messageStore.setMessage("Error loading Posts", "error");
      Clapped.value = false;
    }
  };
  
  function formatClapCount(num: number): string {
    if (num >= 1000000000 && num < 1000000000000) {
      const rounded = Math.round(num / 100000000) / 10;
      return rounded.toFixed(1) + "B";
    } else if (num >= 1000000 && num < 1000000000) {
      const rounded = Math.round(num / 100000) / 10;
      return rounded.toFixed(1) + "M";
    } else if (num >= 1000 && num < 1000000) {
      const rounded = Math.round(num / 100) / 10;
      return rounded.toFixed(1) + "k";
    }
    return num.toString();
  }
  
  let utterance: SpeechSynthesisUtterance | null = null;
  const speechSynthesis = window.speechSynthesis;
  
  
  
  function readBlogPost(content: string) {
    function cleanText(content: string): string {
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = content;
      return tempDiv.textContent || tempDiv.innerText || "";
    }
  
    if (utterance) {
      stopSpeech();
    }
    isPlaying.value = true;
  
    const removeEmojis = (text: string): string => {
      return text.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|[\uD83C-\uDBFF\uDC00-\uDFFF]+|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDE4F\uDE80-\uDEFF\uD83E\uDD00-\uDDFF])/g, '');
    };
  
    // Clean the content
    const cleanedContent = removeEmojis(cleanText(content));
    utterance = new SpeechSynthesisUtterance(cleanedContent);
    speechSynthesis.speak(utterance);
  }
  
  
  function pauseSpeech() {
    speechSynthesis.pause();
    isPlaying.value = false;
  }
  
  function resumeSpeech() {
    if (utterance) {
      speechSynthesis.resume();
    }
  }
  
  function stopSpeech() {
    if (utterance) {
      speechSynthesis.cancel();
      utterance = null;
    }
  }
  
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  function stripHtmlTagsAndImages(htmlContent:string) {
  // Create a temporary element to hold the HTML content
  const tempElement = document.createElement('div');
  
  // Set the HTML content to the element
  tempElement.innerHTML = htmlContent;

  // Remove all <img> elements (with base64 data or any src)
  const images = tempElement.querySelectorAll('img');
  images.forEach(img => img.remove());

  // Get the text content from the element
  const text = tempElement.textContent || tempElement.innerText || '';

  // Return the cleaned-up text (trim spaces and remove multiple spaces)
  return text.replace(/\s+/g, ' ').trim();
}
function getSentenceBeforeComma(text:string) {
  // Split the sentence by the first comma
  const firstCommaIndex = text.indexOf('.');
  
  // If there is no comma, return the entire text
  if (firstCommaIndex === -1) {
    return text;
  }
  
  // Return the part of the sentence before the first comma
  return text.substring(0, firstCommaIndex);
}

  
// Define post data using refs for better reactivity with useHead
const post = ref({
  title: '',
  description: '',
  featuredImage: '',
  content: ''
})
  
  onMounted(async () => {
    try {
      isLoading.value = true;
      const blogReq:any = await BlogApi.getPost(postId.value);
      singlePost.value = blogReq;
      document.title = singlePost.value.title + "- CAMLDS Digest";
       // Set the post data
       post.value = {
      title: blogReq.title + " - CAMLDS Digest", // Set post data
      description:getSentenceBeforeComma(stripHtmlTagsAndImages(blogReq.content)),
      featuredImage: blogReq.img_link,
      content: stripHtmlTagsAndImages(blogReq.content)
    }
    useHead({
      title: post.value.title,
      meta: [
        { name: 'description', content: post.value.description },
        { property: 'og:title', content: post.value.title },
        { property: 'og:description', content: post.value.description },
        { property: 'og:image', content: post.value.featuredImage },
        { property: 'og:type', content: 'article' },
        { property: 'og:url', content: window.location.href },
        
        { name: 'twitter:card', content: 'summary_large_image' }, 
        { name: 'twitter:title', content: post.value.title }, 
        { name: 'twitter:description', content: post.value.description },
        { name: 'twitter:image', content: post.value.featuredImage }, 

        // Additional meta tags for better visibility (optional)
        { name: 'twitter:creator', content: '@CAMLDS_' }
      ]
    })
      isLoading.value = false;
    } catch (error) {
      isLoading.value = true;
      isLoading.value = false;
      apiCallFailed.value = true;
    //   messageStore.setMessage("Error loading posts", "error");
    }
  });

  
  onUnmounted(()=>{
    stopSpeech();
    pauseSpeech()
  })
  
  watch(route, (newRoute, oldRoute) => {
    stopSpeech();
  });
  
  const newPost = async () => {
    try {
      const blogReq:any = await BlogApi.getBlogPosts();
     const filteredPost= blogReq.results.filter((item:any)=> item.post_id !== postId.value);
      // const filteredData = blogReq.data.filter(item => item.id != postId.value);
      filteredPost.sort((a: any, b: any) => new Date(b.date).getTime() - new Date(a.date).getTime());
      recentPosts.value = filteredPost.slice(0, 2);
    } catch (error) {
    
    }
  };
  
  newPost();
  </script>
  
  <style scoped>
  .blog-content h2 {
    @apply text-xl sm:text-2xl xl:text-2xl sm:mt-8 mt-5 text-[#101727] font-semibold;
  }
  .blog-content h3 {
    @apply text-xl sm:text-2xl xl:text-2xl sm:mt-8 mt-5 text-[#101727] font-semibold;
  }
  .blog-content p {
    @apply text-lg sm:my-4 my-3 font-normal;
  }
  .blog-content li {
    @apply list-disc sm:ml-7 ml-7;
  }
  
  .blog-content  a {
    @apply underline font-bold hover:text-purple-800 italic;
  }
  span{
    @apply underline hover:text-purple-800 italic;
  }
  .blog-content img {
    @apply max-w-[763px] max-h-[522px] flex justify-center content-center items-center object-fill rounded-md;
  }

  .loader {
    width: 60px;
    aspect-ratio: 1;
    display: flex;
    animation: l8-0 2s infinite sptes(1);
  }
  .loader::before,
  .loader::after {
    content: "";
    flex: 1;
    animation: 
      l8-1 1s infinite linear alternate,
      l8-2 2s infinite steps(1) -.5s;
  }
  .loader::after {
    --s:-1,-1;
  }
  @keyframes l8-0 {
    0%  {transform: scaleX(1)  rotate(0deg)}
    50% {transform: scaleX(-1) rotate(-90deg)}
  }
  @keyframes l8-1 {
    0%,
    5%   {transform:scale(var(--s,1)) translate(0px)   perspective(150px) rotateY(0deg) }
    33%  {transform:scale(var(--s,1)) translate(-10px) perspective(150px) rotateX(0deg) }
    66%  {transform:scale(var(--s,1)) translate(-10px) perspective(150px) rotateX(-180deg)}
    95%,
    100% {transform:scale(var(--s,1)) translate(0px)   perspective(150px) rotateX(-180deg)}
  }
  @keyframes l8-2 {
    0%  {background:#FEAE49}
    50% {background:#581699}
  }
  
  </style>
  
<template>
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.25 18.75H9C7.9 18.75 7 19.65 7 20.75V21H6C5.59 21 5.25 21.34 5.25 21.75C5.25 22.16 5.59 22.5 6 22.5H18C18.41 22.5 18.75 22.16 18.75 21.75C18.75 21.34 18.41 21 18 21H17V20.75C17 19.65 16.1 18.75 15 18.75H12.75V16.46C12.5 16.49 12.25 16.5 12 16.5C11.75 16.5 11.5 16.49 11.25 16.46V18.75Z"
      fill="#743DAA"
    />
    <path
      d="M18.4793 12.14C19.1393 11.89 19.7193 11.48 20.1793 11.02C21.1093 9.99 21.7193 8.76 21.7193 7.32C21.7193 5.88 20.5893 4.75 19.1493 4.75H18.5893C17.9393 3.42 16.5793 2.5 14.9993 2.5H8.9993C7.4193 2.5 6.0593 3.42 5.4093 4.75H4.8493C3.4093 4.75 2.2793 5.88 2.2793 7.32C2.2793 8.76 2.8893 9.99 3.8193 11.02C4.2793 11.48 4.8593 11.89 5.5193 12.14C6.5593 14.7 9.0593 16.5 11.9993 16.5C14.9393 16.5 17.4393 14.7 18.4793 12.14ZM14.8393 8.95L14.2193 9.71C14.1193 9.82 14.0493 10.04 14.0593 10.19L14.1193 11.17C14.1593 11.77 13.7293 12.08 13.1693 11.86L12.2593 11.5C12.1193 11.45 11.8793 11.45 11.7393 11.5L10.8293 11.86C10.2693 12.08 9.8393 11.77 9.8793 11.17L9.9393 10.19C9.9493 10.04 9.8793 9.82 9.7793 9.71L9.1593 8.95C8.7693 8.49 8.9393 7.98 9.5193 7.83L10.4693 7.59C10.6193 7.55 10.7993 7.41 10.8793 7.28L11.4093 6.46C11.7393 5.95 12.2593 5.95 12.5893 6.46L13.1193 7.28C13.1993 7.41 13.3793 7.55 13.5293 7.59L14.4793 7.83C15.0593 7.98 15.2293 8.49 14.8393 8.95Z"
      fill="#743DAA"
    />
  </svg>
</template>

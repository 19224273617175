import { axios$ } from "..";

export async function getBlogPosts() {
  try {
    const response = await axios$.get(`/blog/posts/`);
    return response;
  } catch (error) {
    throw error;
  }
}

export async function getPost(post_id: string) {
    try {
      const response = await axios$.get(`/blog/posts/${post_id}/`);
      return response;
    } catch (error) {
      throw error;
    }
  }

export async function clap(post_id: string) {
    try {
      const response = await axios$.post(`/blog/clap/${post_id}/`);
      return response;
    } catch (error) {
      throw error;
    }
  }




<template>
    <div class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 hidden">
      <div class="bg-white p-6 rounded-lg w-11/12 max-w-lg max-h-[90vh] no-scrollbar  overflow-y-auto">
        <h2 class="text-xl font-bold mb-4 text-center">Registration Form</h2>
        <form @submit.prevent="handleSubmit">
          <div class="mb-4">
            <label class="block mb-1" for="name">Name</label>
            <input id="name" v-model="form.name" type="text" class="w-full border rounded px-3 py-2" required />
          </div>
          <div class="mb-4">
            <label class="block mb-1" for="email">Email</label>
            <input id="email" v-model="form.email" type="email" class="w-full border rounded px-3 py-2" required />
          </div>
          <div class="mb-4">
            <label class="block mb-1" for="phone">Phone</label>
            <input id="phone" v-model="form.phone" type="tel" class="w-full border rounded px-3 py-2" required />
          </div>
          <div class="mb-4">
            <label class="block mb-1" for="school">School</label>
            <input id="school" v-model="form.school" type="text" class="w-full border rounded px-3 py-2" required />
          </div>
          <div class="mb-4">
            <label class="block mb-1" for="role">Role</label>
            <select id="role" v-model="form.role" class="w-full border rounded px-3 py-2" required>
              <option value="">Select a Role</option>
              <option value="Team Lead">Team Lead</option>
              <option value="Technical Lead">Technical Lead</option>
              <option value="PR Lead">PR Lead</option>
            </select>
          </div>
          <div class="mb-4">
            <label class="block mb-1" for="experience">Relevant Experience</label>
            <textarea id="experience" v-model="form.experience" rows="4" class="w-full border rounded px-3 py-2" required></textarea>
          </div>
          <div class="mb-4">
            <label class="block mb-1" for="why">Why are you interested in this role?</label>
            <textarea id="why" v-model="form.why" rows="4" class="w-full border rounded px-3 py-2" required></textarea>
          </div>
          <div class="mb-4">
            <label class="block mb-1" for="skills">Skills</label>
            <input id="skills" v-model="form.skills" type="text" class="w-full border rounded px-3 py-2" required />
          </div>
          <div class="mb-4">
            <label class="block mb-1" for="availability">Availability</label>
            <input id="availability" v-model="form.availability" type="text" class="w-full border rounded px-3 py-2" required />
          </div>
          <div class="mb-4">
            <label class="block mb-1" for="goals">Goals</label>
            <textarea id="goals" v-model="form.goals" rows="4" class="w-full border rounded px-3 py-2" required></textarea>
          </div>
          <button type="submit" class="bg-primary-orange text-primary-purple py-2 px-12 rounded-[30px]">Submit</button>
          <button @click="$emit('close')" class="ml-2 text-gray-600">Close</button>
        </form>
      </div>
    </div>
  </template>
  
  <script lang="ts" setup>
  import { ref } from 'vue';
  
  const form = ref({
    name: '',
    email: '',
    phone: '',
    school: '',
    role: '',
    experience: '',
    why: '',
    skills: '',
    availability: '',
    goals: ''
  });
  
  const handleSubmit = () => {
    console.log('Form submitted:', form.value);
  };
  </script>
  
  <style scoped>
  </style>
  
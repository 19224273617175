<template>
    <transition name="fade">
            <div @click.self="closeModal" v-if="isVisible" class="fixed inset-0 bg-black bg-opacity-50  flex items-center justify-center z-50">
              <div class="bg-white rounded-lg shadow-lg w-full max-w-3xl mx-auto px-4">
                <div class="flex items-center justify-between p-4 border-b">
                  <h5 class="text-lg font-semibold exo" id="exampleModalToggleLabel">Contact Us</h5>
                  <button type="button" class="text-gray-500 hover:text-gray-700" @click="closeModal">
                    <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
                    </svg>
                  </button>
                </div>
                <div class="p-4">
                  <div class="mb-6">
                    <h3 class="text-lg font-semibold mb-3 exo">Call and Enquiry</h3>
                    <div class="mb-4">
                      <p class="mb-0">
                        <a href="#" class="text-primary ms-0 h4">We</a>
                        provide free consultation related to the subject matter after the course.
                        Please email your queries to <mark>enquiry@camlds.org</mark> or send us a WhatsApp message on <mark>+234808-894-3064</mark> and we will forward
                        your queries to the subject matter experts and get back to you asap.
                      </p>
                    </div>
                  </div>
                  <div class="mb-6">
                    <h3 class="text-lg font-semibold mb-3 exo">Support</h3>
                    <div class="mb-4">
                      <p class="mb-0">
                        <a href="#" class="text-primary ms-0 h4">You</a> can cancel or re-schedule the course due to unforeseen circumstances.
                        If the course is cancelled, we will <code>refund 100%</code> to participants.
                      </p>
                      <p>
                        <mark>Note</mark> that the minimal number of participants to start a class is 3.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="flex justify-end p-4 border-t">
                  <button class="bg-primary-purple text-white px-4 py-2 rounded hover:bg-purple-950" @click="closeModal">OK</button>
                </div>
              </div>
            </div>         
      </transition>
</template>

<script setup lang="ts">
import { ref } from 'vue';
  
  const isVisible = ref(true);
  const closeModal = () => {
    isVisible.value = false;
  };
  </script>
  
  <style scoped>
  .fade-enter-active, .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
  </style>

<template>
  <div
    class="h-[32px] flex items-center justify-center exo bg-[#6F38A5] text-white font-[800] sm:text-base text-sm !z-40"
  >
    <span
      >Centre for <span class="text-primary-orange">Applied</span> Machine
      Learning and Data Science</span
    >
  </div>

  <section
    class="w-full max-w-[95vw] sm:max-w-[754px] xl:max-w-[1280px] mx-auto"
  >
    <a
    href="/"
     class="w-[147px] h-10 mx-auto mb-6 block">
      <img src="https://res.cloudinary.com/dp0whcqar/image/upload/v1724245747/campus_network_logo_rn8zjr.png" alt="logo" />
    </a>

    <div
      class="md:mt-[89px] mt-[102px] lg:text-[52px] text-[32px] md:text-[38px] text-primary-purple exo text-center font-bold md:w-full w-[342px] mx-auto"
    >
    Campus <span class="text-primary-orange">  Network</span> Leads
    </div>
    <p class="md:mt-6 mt-4 lg:text-center text-left text-[#3B0545] ">
      Are you a student (undergraduate) interested in tech and seeking to learn,
      grow and contribute to a community of tech professionals?
    </p>
     

    <p class="-mt-4 lg:text-center text-left text-[#292D32]">
      Applications for the CAMLDS Campus Network Lead positions - The Team Lead,
      Technical Lead and PR lead are now open. This is an opportunity for you to
      lead a tech community in your school. A community to introduce students
      into tech to learn and build projects that solve real life problems. 
    </p>

    <div
      class="flex lg:flex-row flex-col justify-between items-center md:my-[56px] my-10 gap-6 text-[#292D32]"
    >
      <div class="lg:w-1/2 w-full">
        <div class="title exo mt-6 !font-[600]">Eligibility criteria</div>
        <p class="mt-6">
          You must satisfy these criteria to apply as a team lead for your
          school:
        </p>

        <ol class="mt-6 list-disc px-8 max-w-[489px]">
          <li>Enrollment in a tertiary institution is a prerequisite.</li>
          <li>A minimum of one year before graduation is necessary.</li>
          <li>
            Must be able to demonstrate passion and focus over the course of a
            year.
          </li>
          <li>
            Should have experience in team leadership and planning. 
          </li>
          <li>
            Tech Savvy.
          </li>
        </ol>

        <a
        href="https://docs.google.com/forms/d/e/1FAIpQLSfWJUz41WziqAc7el5Tb4lj8fhAV8rUfz1qfW03fZQ4BnjVGg/viewform"
        target="_blank"
          class="hidden md:block font-semibold bg-primary-orange text-primary-purple rounded-[30px] py-3 px-[84px] mt-12 text-xl hover:scale-105 w-fit"
        >
          Apply 
      </a>
      </div>
      <div class="lg:w-1/2 w-full flex lg:justify-end justify-start items-center">
        <img
          class="w-[543px] md:h-[381px] h-[252px]"
          src="https://res.cloudinary.com/dp0whcqar/image/upload/v1723730095/Frame_1171276805_qfduow.png"
          alt="campus talk"
        />
      </div>
    </div>
    <a
        href="https://docs.google.com/forms/d/e/1FAIpQLSfWJUz41WziqAc7el5Tb4lj8fhAV8rUfz1qfW03fZQ4BnjVGg/viewform"
        target="_blank"
      class="md:hidden block font-semibold bg-primary-orange text-primary-purple w-fit rounded-[30px] py-4 px-[84px] mt-12 text-xl hover:scale-105 mb-10"
    >
      Apply 
  </a>

    <div class="flex lg:flex-row flex-col-reverse justify-between items-center md:my-[56px] my-10 gap-6">
        <div class="lg:w-1/2 w-full flex lg:justify-start justify-start items-center">
            <img class="w-[543px] md:h-[381px] h-[252px] " src="https://res.cloudinary.com/dp0whcqar/image/upload/v1723731407/Frame_1171276805_1_m0pkvr.png" alt="campus talk" />
        </div>

        <div class="lg:w-1/2 w-full text-[#292D32]">
            <div class="title exo  mt-6 !font-[600] lg:pr-12 pr-0">Benefits of being a lead on the CAMLDS Campus Network</div>
            
            <ol class="mt-6 list-disc px-8">
                <li>Skill development with training programs</li>
                <li> An opportunity to build capacity as a student by contributing actively to the CAMLDS Campus Network community.</li>
                <li>Access to exclusive internship and job opportunities</li>
                <li>Gain recognition for your achievements and projects as a leader in your school and campus network, enhancing your professional portfolio</li>
                <li>Inter School competitions and hackathons.</li>
                <li>An opportunity to grow your knowledge in tech through peer and industry Networking.</li>
                <li>Leadership Experience</li>
                <li>Personal development in building soft skills in tech.</li>
                <li>Participation in an inclusive community with continuous support.</li>
            </ol>
    
           </div>
    </div>


    <div class="title font-bold text-center md:!text-[48px] text-[32px] text-primary-purple">Campus <span class="text-primary-orange">Network</span> Roles</div>
    <p class="text-center">Ensure you meet the general criteria above before you can apply for any role</p>


    <div class="flex lg:flex-row flex-col justify-between items-start md:my-[56px] my-10 gap-6 text-[#292D32]">
        <div class="lg:w-1/3 w-full ">
            <div class="title exo  mt-6 !font-[600] lg:pr-12 pr-0">School Team-Lead</div>
            
            <ol class="mt-6 list-disc px-8">
                <li>Must be Interested in tech</li>
                <li>Strong organizational skill</li>
                <li>Good leadership qualities</li>
                <li>Basic technical knowledge </li>
                <li>Excellent communication skills.</li>
                <li>Must be able to mobilize and work with students</li>
                <li>Prior experience in leadership roles or event organization is an advantage but not compulsory. </li>
                <li>Excellent Team spirit</li>
                <li>Discipline in any IT department (Computer Engineering or Computer Science) is an advantage but not compulsory.</li>
            </ol>
    
           </div>

           <div class="lg:w-1/3 w-full ">
            <div class="title exo  mt-6 !font-[600] lg:pr-12 pr-0">Technical Team-Lead</div>
            
            <ol class="mt-6 list-disc px-8">
                <li>Must be tech-savvy </li>
                <li>Must have basic knowledge across two or more tech skills.</li>
                <li>Should be able to teach </li>
                <li>Good communication skills.</li>
                <li>Relevant technical projects, or internships are an advantage.</li>
                <li>Good leadership qualities</li>
                <li>Excellent Team spirit</li>
                <li>Discipline in any IT department (Computer Engineering or Computer Science) is an advantage but not compulsory.</li>
                
            </ol>
    
           </div>

           <div class="lg:w-1/3 w-full ">
            <div class="title exo  mt-6 !font-[600] lg:pr-12 pr-0">PR Lead</div>
            
            <ol class="mt-6 list-disc px-8">
                <li>Must have strong public speaking skills.</li>
                <li> Must have good networking and people skills. </li>
                <li>Should possess a good level of Creativity. </li>
                <li>The ability to create and manage content for promotion.</li>
                <li>Experience in marketing, event planning, graphic design or content creation would be an added advantage.</li>
               
            </ol>
    
           </div>
    </div>

    <a
        href="https://docs.google.com/forms/d/e/1FAIpQLSfWJUz41WziqAc7el5Tb4lj8fhAV8rUfz1qfW03fZQ4BnjVGg/viewform"
        target="_blank"
    class="mx-auto w-fit block font-semibold bg-primary-orange text-primary-purple rounded-[30px] py-3 px-[84px] mt-12 text-xl hover:scale-105 my-12"
  >
    Apply Now
  </a>
  </section>

  <section class="relative sm:mb-[40px] mb-[70px]">
    <img src="../../assets/footerBG.png" class="w-full h-[380px] md:h-80 sm:h-full object-cover"/>
    <div
      data-aos="zoom-in"
      class="absolute inset-0 flex flex-col items-center justify-center"
    >
      <div class="title mb-2 !text-white text-center font-bold">The Center for Applied Machine Learning and Data Science</div>
      <div class="avenir text-base text-primary-orange sm:mb-7 text-center mb-4 max-w-[95vw] sm:max-w-[754px] xl:max-w-[1280px] mx-auto">To know more about Center for Applied Machine Learning and Data Science </div>
      
      <router-link to="/"><div class="avenir cursor-pointer text-white sm:py-4 sm:px-8 py-3 px-4 w-fit bg-primary-orange rounded-lg hover:shadow-lg hover:scale-105">Visit Us</div></router-link>
      
    </div>
  </section>


  <RegistrationForm v-if="showForm" @close="showForm = false" />
  <FooterSection />
  
</template>

<script setup lang="ts">
import FooterSection from '../HomeView/FooterSection.vue';
import RegistrationForm from '../../components/ModalComp/CampusRegForm.vue';
import { ref } from 'vue';

const showForm = ref(false);
</script>

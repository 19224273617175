<template>
  <section class="mt-20 bg-[#581699] text-white sm:pb-2 py-16">
    <div
    data-aos="fade-up"
      class="max-w-[95vw] sm:max-w-[754px] xl:max-w-[1280px] mx-auto py-[36px] sm:px-[71px] px-8"
    >
      <div class="flex justify-start items-center gap-2">
        <ProfileAvatarIconComp />
        <div class="!text-primary-orange title">CAMLDS</div>
      </div>

      <div class="content mt-5 text-justify">
        During the program, students work on commercial data science problems
        with companies, which include startups, SMEs, charities,
        Non-Governmental Organizations (NGO), Governments, and large
        multinational companies. There will be a huge range of different
        projects, and students' preferences will be taken into consideration.
      </div>

      <div class="flex lg:flex-row flex-col justify-center items-center gap-4 w-full">
        <div
          v-for="term in keyTerms"
          :key="term.title"
          class="flex lg:flex-row flex-col justify-center items-center gap-2 mt-11"
        >
          <div
            class="flex flex-col justify-start items-center gap-2 bg-white sm:w-[272px] w-full sm:h-[410px] h-[378px] rounded-[10px] px-5 py-6"
          >
            <component
              :is="term.icon"
              class="h-[62px] w-[62px] "
            />
            <div class="text-[#06094E] exo text-xl font-semibold mt-4">
              {{ term.title }}
            </div>
            <div class="text-[#737373] sm:text-[15px] text-base font-normal mt-[14px]">
              {{ term.content }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="relative sm:mb-[40px] mb-[70px]">
    <img src="../../assets/footerBG.png" class="w-full h-60 md:h-80 sm:h-full object-cover"/>
    <div
      data-aos="zoom-in"
      class="absolute inset-0 flex flex-col items-center justify-center"
    >
      <div class="title mb-2 !text-white font-bold">Call For Volunteer Instructors</div>
      <div class="avenir text-base text-primary-orange sm:mb-7 text-center mb-4 max-w-[95vw] sm:max-w-[754px] xl:max-w-[1280px] mx-auto">be part of the movement that’s shaping the future of technology and innovation in Africa. Your journey to becoming a top-tier CAMLDS practitioner starts here!</div>
      
      <router-link to="/become-an-instructor"><div class="avenir cursor-pointer text-white sm:py-4 sm:px-8 py-3 px-4 w-fit bg-primary-orange rounded-lg hover:shadow-lg hover:scale-105">Sign up as a Volunteer</div></router-link>
      
    </div>
  </section>

</template>

<script setup lang="ts">
import ProfileAvatarIconComp from "../../components/IconComp/ProfileAvatarIconComp.vue";
import { ref } from "vue";
import MLIconComp from "../../components/IconComp/MLIconComp.vue";
import DSIconComp from "../../components/IconComp/DSIconComp.vue";
import NLPIconComp from "../../components/IconComp/NLPIconComp.vue";
import CVIconComp from "../../components/IconComp/CVIconComp.vue";

const keyTerms = ref([
  {
    title: "Machine Learning",
    icon: MLIconComp,
    content:
      "Machine learning (ML) is the scientific study of algorithms and statistical models that computer systems use to perform a specific task without using explicit instructions, relying on patterns and inference instead.",
  },

  {
    title: "Data Science",
    icon: DSIconComp,
    content:
      "Data science is a multi-disciplinary field that uses scientific methods, processes, algorithms and systems to extract knowledge and insights from structured and unstructured data.",
  },

  {
    title: "Generative AI",
    icon: CVIconComp,
    content:
      "Generative Artificial Intelligence fuels creativity, enabling personalized content and experiences, unlocking new avenues for entertainment, art, and self-expression appealing to Gen Z's digital-native sensibilities.",
  },

  {
    title: "NLP/NLU",
    icon: NLPIconComp,
    content:
      "Natural language processing (NLP) and Natural Language Understanding (NLU) are subfield of linguistics, computer science, information engineering, and artificial intelligence concerned with the interactions between computers and human (natural) languages.",
  },
]);
</script>

import axios from "axios";

const integrationToken = `Bearer ${process.env.VUE_APP_INTERGRATION_TOKEN}`;

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_Base_url,
  timeout: 10000,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    // Add the integration token to the Authorization header
    config.headers["Integration-Token"] =
      process.env.VUE_APP_INTERGRATION_TOKEN;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    
    return response.data;
  },
  (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

export default axiosInstance;
